import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@src/environments/environment';
import { CitySearchInterface } from '@libs/city-search/city-search.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  apiPath = environment.apiUrl;

  constructor(private http: HttpClient) {}

  search(term: string): Observable<CitySearchInterface[]> {
    return this.http.get<CitySearchInterface[]>(
      this.apiPath + '/cities/search?term=' + encodeURIComponent(term)
    );
  }
}
