import { Component, Input } from '@angular/core';
import { ContactInterface } from '@features/contacts/domain/entities/contacts.interface';

@Component({
  selector: 'app-mandat-detail-card-physique',
  templateUrl: './mandat-detail-card-physique.component.html',
  styleUrl: './mandat-detail-card-physique.component.scss',
})
export class MandatDetailCardPhysiqueComponent {
  @Input() contact!: ContactInterface;

  checkData(data: string | number): string | number {
    return data || 'Aucune donnée disponible';
  }
}
