import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ShortPappersCompanyInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies-short.interface';
import { Subject } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { PappersService } from './services/search-pappers.service';
import { IdGeneratoreService } from '@src/shared/id-generator/id-generatore.service';

@Component({
  selector: 'app-search-pappers',
  templateUrl: './search-pappers.component.html',
  styleUrls: ['./search-pappers.component.scss'],
})
export class SearchPappersComponent implements OnInit {
  @Output() optionSelectedEvent: EventEmitter<ShortPappersCompanyInterface> =
    new EventEmitter<ShortPappersCompanyInterface>();
  @Input() required = false;
  @Input() forDesktop = false;
  searchInput: FormControl = new FormControl();
  searchResults: ShortPappersCompanyInterface[] = [];
  loading = false;
  searchForm!: FormGroup;
  optionSelected = false;
  currentSearchTerm!: string;
  public inputId = this.idGeneratorService.generateShortenedId();
  isInputFocused!: boolean;
  isTouched = false;
  private searchSubject: Subject<string> = new Subject<string>();

  constructor(
    private papersService: PappersService,
    private cdr: ChangeDetectorRef,
    private idGeneratorService: IdGeneratoreService
  ) {}

  target(event: KeyboardEvent): HTMLInputElement {
    if (!(event.target instanceof HTMLInputElement)) {
      throw new Error('wrong target');
    }
    return event.target;
  }

  ngOnInit(): void {
    this.searchInput.valueChanges
      .pipe(
        debounceTime(300),
        filter(searchTerm => !!searchTerm.trim()),
        tap(() => (this.loading = true)),
        switchMap(searchTerm =>
          this.papersService.suggestionUnitelegale(searchTerm)
        )
      )
      .subscribe({
        next: (results: ShortPappersCompanyInterface[]): void => {
          this.searchResults = results;
          this.currentSearchTerm = this.searchInput.value;
          this.loading = false;
          this.cdr.markForCheck();
        },
        error: error => {
          console.error('Erreur lors de la recherche:', error);
          this.loading = false;
          this.cdr.markForCheck();
        },
      });
  }

  checkIfValueExist(): boolean {
    return this.currentSearchTerm !== '';
  }

  onSearchInputChange(searchTerm: string): void {
    if (this.optionSelected) {
      this.optionSelected = false;
      return;
    }
    this.searchSubject.next(searchTerm);
  }

  onFocus(b: boolean) {
    this.isInputFocused = b;
    this.isTouched = true;
    this.cdr.detectChanges();
  }

  onAutocompleteOpened() {
    this.loading = false;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.searchInput.setValue(event.option.value.nom_entreprise);
    this.optionSelected = true;
    this.optionSelectedEvent.emit(event.option.value);
  }

  isNumericSearchTerm(): boolean {
    return /^\d+$/.test(this.currentSearchTerm);
  }
}
