import { Component, Input } from '@angular/core';
import { PappersCompaniesInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies.interface';

@Component({
  selector: 'app-mandat-detail-card-morale',
  templateUrl: './mandat-detail-card-morale.component.html',
  styleUrl: './mandat-detail-card-morale.component.scss',
})
export class MandatDetailCardMoraleComponent {
  @Input() companie!: PappersCompaniesInterface;

  checkData(data: string | number): string | number {
    return data || 'Aucune donnée disponible';
  }
}
