import { FormControl, FormGroup } from '@angular/forms';
import { FormSectionBase } from '@src/shared/forms/form.class';

export class MandatCommentaireForm extends FormSectionBase {
  createFormGroup(): FormGroup {
    return new FormGroup({
      commentaire: new FormControl(''),
    });
  }
}
