<form [formGroup]="annonceForm" class="create-form">
  <div class="form-group form-group-textarea">
    <app-input-slide
      [inputType]="'text'"
      [required]="true"
      formControlName="titre"
      id="annonce-title"
      placeholder="Titre"
      type="text"
      width="fit" />
  </div>
  <div class="form-group form-group-textarea">
    <label for="annonce-description">Description :</label>
    <textarea formControlName="description" id="annonce-description"></textarea>
  </div>
</form>
