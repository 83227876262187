import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MoraleOrPhysiqueEnum } from '@features/mandats/domain/entities/mandat.interface';
import { ShortPappersCompanyInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies-short.interface';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@libs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-bailleur-companies',
  templateUrl: './bailleur-companies.component.html',
  styleUrls: ['./bailleur-companies.component.scss'],
})
export class BailleurCompaniesComponent implements OnInit {
  @Input({ required: true }) companiesBailleurForm!: FormGroup;
  @Input({ required: true }) mandatPredefinedForm!: FormGroup;
  @Input() isEditMode: boolean = false;
  moralOrPhysicalControl: FormControl<boolean | null> = new FormControl(false);
  shouldDisplaySearchCompaniesBar: boolean = true;
  private storedData: any = null;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.shouldDisplaySearchCompaniesBar =
      !this.isEditMode || !this.companiesBailleurForm.value;

    this.mandatPredefinedForm
      .get('morale_or_physique_bailleur')
      ?.setValue(
        this.moralOrPhysicalControl.value === true
          ? MoraleOrPhysiqueEnum.physique
          : MoraleOrPhysiqueEnum.morale
      );

    this.moralOrPhysicalControl.valueChanges.subscribe(
      (value: boolean | null) => {
        value ? this.confirmSetPhysical() : this.confirmSetMoral();
      }
    );
  }

  confirmSetPhysical(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Voulez-vous vraiment changer pour personne physique ?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.setPhysical();
      } else {
        this.moralOrPhysicalControl.setValue(false, { emitEvent: false });
      }
    });
  }

  confirmSetMoral(): void {
    if (this.storedData) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { message: 'Voulez-vous utiliser les données en mémoire ?' },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.companiesBailleurForm.setValue(this.storedData);
        }
        this.setMoral();
      });
    } else {
      this.setMoral();
    }
  }

  setPhysical(): void {
    this.mandatPredefinedForm
      .get('morale_or_physique_bailleur')
      ?.setValue(MoraleOrPhysiqueEnum.physique);
    this.storedData = this.companiesBailleurForm.value;
    this.companiesBailleurForm.reset();
    this.shouldDisplaySearchCompaniesBar = true;
  }

  setMoral(): void {
    this.mandatPredefinedForm
      .get('morale_or_physique_bailleur')
      ?.setValue(MoraleOrPhysiqueEnum.morale);
    this.shouldDisplaySearchCompaniesBar = !this.companiesBailleurForm.value;
  }

  enableForm(): void {
    this.shouldDisplaySearchCompaniesBar = true;
  }

  getPappersResult(event: ShortPappersCompanyInterface): void {
    this.companiesBailleurForm.patchValue({
      forme_juridique: event.forme_juridique,
      adresse_ligne_1: event.adresse_ligne_1,
      adresse_ligne_2: event.adresse_ligne_2,
      code_postal: event.code_postal,
      ville: event.ville,
      complement_adresse: event.complement_adresse,
      denomination: event.denomination,
      domaine_activite: event.domaine_activite,
      indice_repetition: event.indice_repetition,
      libelle_code_naf: event.libelle_code_naf,
      libelle_voie: event.libelle_voie,
      nic: event.nic,
      nom: event.nom,
      nom_entreprise: event.nom_entreprise,
      numero_voie: event.numero_voie,
      prenom: event.prenom,
      siren: event.siren,
      siren_formate: event.siren_formate,
      type_search: event.type_search,
      type_voie: event.type_voie,
    });
    this.shouldDisplaySearchCompaniesBar = false;
  }
}
