import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CitySearchComponent } from './city-search.component';
import { FormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { MatIcon } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatAutocomplete,
    MatOption,
    MatAutocompleteTrigger,
    MatIcon,
  ],
  declarations: [CitySearchComponent],
  exports: [CitySearchComponent],
})
export class AppCitySearchModule {}
