<div class="mandant-detail">
  <h2
    *ngIf="
      mandat.mandat.id_forme_juridique === 4 &&
        mandat.mandat.etat_murs === 'busy';
      else bailleurBlock
    ">
    Locataire
  </h2>
  <ng-template #bailleurBlock><h2>Bailleur</h2></ng-template>
  <app-mandat-detail-card-morale
    *ngIf="mandat.bailleurCompanies && mandat.bailleurCompanies.siren"
    [companie]="mandat.bailleurCompanies" />
  <app-mandat-detail-card-physique
    *ngIf="mandat.bailleurContact"
    [contact]="mandat.bailleurContact" />
</div>
