import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormSectionBase } from '@src/shared/forms/form.class';

export class MandatAnnonceForm extends FormSectionBase {
  createFormGroup(): FormGroup {
    return new FormGroup({
      titre: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });
  }
}
