import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormSectionBase } from '@src/shared/forms/form.class';

export class FinancialForm extends FormSectionBase {
  private readonly isRequired: boolean;

  constructor(isRequired: boolean) {
    super();
    this.isRequired = isRequired;
  }

  createFormGroup(): FormGroup {
    const baseFormConfig = {
      loyer_annuel: new FormControl(
        this.isRequired ? '' : null,
        this.isRequired ? Validators.required : []
      ),
      total_produits_exploit: new FormControl(''),
      ca: new FormControl(''),
      achats: new FormControl(''),
      variation_stock: new FormControl(''),
      charges_externes: new FormControl(''),
      charges_locatives: new FormControl(''),
      impots: new FormControl(''),
      salaires_total: new FormControl(''),
      salaires_exploitant: new FormControl(''),
      charges_sociales_total: new FormControl(''),
      charges_sociales_exploitant: new FormControl(''),
      amortissements: new FormControl(''),
      autres_charges: new FormControl(''),
      montant_ebe: new FormControl(''),
      provision_mensuel: new FormControl(''),
      provision_tva: new FormControl(''),
      revision_annuelle: new FormControl(''),
      foncier_a_charge: new FormControl(''),
      montant_foncier: new FormControl(''),
      depot_garantie: new FormControl(''),
    };

    return new FormGroup(baseFormConfig);
  }
}
