import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BuyerDetailInterface } from '@features/buyers/domain/entities/buyers.interface';
import { BuyersSelectors } from '@features/buyers/domain/redux/selectors/buyers.selectors';
import { Store } from '@ngxs/store';
import { IdGeneratoreService } from '@src/shared/id-generator/id-generatore.service';

@Component({
  selector: 'app-select-contact',
  templateUrl: './select-contact.component.html',
  styleUrls: ['./select-contact.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectContactComponent),
      multi: true,
    },
  ],
})
export class SelectContactComponent implements ControlValueAccessor {
  @Input({ transform: booleanAttribute }) forDesktop = false;
  @Input() placeholder!: string;
  @Input() required = false;
  public inputId = this.idGeneratorService.generateShortenedId();
  isInputFocused!: boolean;
  searchTerm = '';
  searchExecuted = false;
  matchingBuyers: BuyerDetailInterface[] = [];
  @Output() optionSelectedEvent: EventEmitter<BuyerDetailInterface> =
    new EventEmitter<BuyerDetailInterface>();

  constructor(
    private store: Store,
    private idGeneratorService: IdGeneratoreService,
    private cdr: ChangeDetectorRef
  ) {}

  onChange: (value: any) => void = () => {};

  onTouched: () => void = () => {};

  public writeValue(value: string) {
    console.log('writeValue', value);
    this.searchTerm = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    // Rien à faire ici dans ce cas
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public updateValue(event: Event) {
    this.searchTerm = (event.target as HTMLInputElement).value;
    this.onChange(this.searchTerm);
  }

  checkIfValueExist(): boolean {
    return this.searchTerm !== '';
  }

  onFocus(b: boolean) {
    this.isInputFocused = b;
    this.cdr.detectChanges();
  }

  executeSearch() {
    if (this.searchTerm.length < 3) {
      this.matchingBuyers = [];
      this.searchExecuted = false;
    } else {
      this.store
        .select(BuyersSelectors.searchBuyers(this.searchTerm))
        .subscribe(result => {
          this.matchingBuyers = result;
          this.searchExecuted = true;
        });
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.optionSelectedEvent.emit(event.option.value);
    this.matchingBuyers = [];
  }
}
