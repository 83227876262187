<div class="diffusion">
  <div class="town">
    <img
      alt="megaphone"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/megaphone.png"
      width="20" />
    <p>{{ mandat.mandat.CPdiffusion }} - {{ mandat.mandat.vilcespme }}</p>
  </div>
  <h2>{{ mandat.mandat.titre }}</h2>
  <p class="description">{{ mandat.mandat.description }}</p>
</div>
<div class="separator"></div>
<div class="comment">
  <img
    alt="bulle commentaire"
    height="20"
    ngSrc="../../../../../../../../assets/mandat-detail/bulle.png"
    width="20" />
  <p *ngIf="mandat.mandat.commentaires; else noCommentBlock">
    {{ mandat.mandat.commentaires }}
  </p>
  <ng-template #noCommentBlock>
    <p>Aucun commentaire</p>
  </ng-template>
</div>
