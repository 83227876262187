import { createSelector, Selector } from '@ngxs/store';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';
import {
  ProspectionMandatsState,
  ProspectionMandatsStateModel,
} from '@features/mandats/domain/redux/state/prospection-mandats.state';

export class MandatsProspectionSelectors {
  @Selector([ProspectionMandatsState])
  static mandats(state: ProspectionMandatsStateModel): MandatDetailInterface[] {
    return state.prospectionMandats;
  }

  static readonly getMandatById = (mandatId: number) => {
    return createSelector(
      [ProspectionMandatsState],
      (state: ProspectionMandatsStateModel) => {
        return state.prospectionMandats.find(
          mandats => mandats.mandat.id === mandatId
        );
      }
    );
  };

  static findMandatByAddress(address: AddressComponentInterface) {
    return createSelector(
      [ProspectionMandatsState],
      (state: ProspectionMandatsStateModel) => {
        return state.prospectionMandats.filter(mandat => {
          return (
            (mandat.salePoints.route === address.route &&
              mandat.salePoints.locality &&
              mandat.salePoints.street_number == address.street_number) ||
            mandat.salePoints.place_id === address.place_id
          );
        });
      }
    );
  }
}
