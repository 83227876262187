import { Component, Input } from '@angular/core';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

@Component({
  selector: 'app-mandat-detail-sale-point',
  templateUrl: './mandat-detail-sale-point.component.html',
  styleUrl: './mandat-detail-sale-point.component.scss',
})
export class MandatDetailSalePointComponent {
  @Input() mandat!: MandatDetailInterface;
  protected readonly parseInt = parseInt;
  protected readonly String = String;
  protected readonly parseFloat = parseFloat;
  protected readonly Boolean = Boolean;

  calculatedNetVendeur(mandat: MandatDetailInterface): number {
    if (
      +mandat.mandat.prix_de_presentation &&
      +mandat.mandat.honoraires_vendeur
    ) {
      const result =
        +mandat.mandat.prix_de_presentation - +mandat.mandat.honoraires_vendeur;
      return result <= 0 ? 0 : result;
    }
    return mandat.mandat.prix_de_presentation;
  }
}
