import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchPappersComponent } from '@libs/search-pappers/search-pappers.component';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';
import { NgClass, NgForOf, NgIf } from '@angular/common';

@NgModule({
  declarations: [SearchPappersComponent],
  imports: [
    FormsModule,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    NgForOf,
    NgIf,
    NgClass,
  ],
  exports: [SearchPappersComponent],
})
export class SearchPappersModule {}
