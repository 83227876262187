import { Injectable } from '@angular/core';
import { AddProspectionMandatsWithoutApiCall } from '@features/mandats/domain/redux/actions/prospection-mandats.actions';
import { Action, State, StateContext } from '@ngxs/store';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

export class ProspectionMandatsStateModel {
  prospectionMandats!: MandatDetailInterface[];
}

export const defaultProspectionMandatState: ProspectionMandatsStateModel = {
  prospectionMandats: [],
};

@State<ProspectionMandatsStateModel>({
  name: 'prospectionMandats',
  defaults: defaultProspectionMandatState,
})
@Injectable()
export class ProspectionMandatsState {
  @Action(AddProspectionMandatsWithoutApiCall)
  addProspectionMandatsWithoutApiCall(
    ctx: StateContext<ProspectionMandatsStateModel>,
    action: AddProspectionMandatsWithoutApiCall
  ) {
    const prospectionMandats = action.payload;
    ctx.patchState({
      prospectionMandats: prospectionMandats,
    });
  }
}
