<div class="mandat-detail-container">
  <app-mandat-detail-mobile-header
    [idActivity]="mandat.mandat.id_activite"
    [mandatState]="mandat.mandat.etat"
    [mandat]="mandat"
    [salePointName]="mandat.salePoints.enseigne" />
  <app-mandat-detail-sub-header [mandat]="mandat" />
  <div class="separator"></div>
  <app-mandat-detail-sale-point [mandat]="mandat" />
  <div class="contact">
    <app-mandat-detail-mandant [mandat]="mandat" />
    <app-mandat-detail-bailleur
      *ngIf="mandat.bailleurContact?.nom"
      [mandat]="mandat" />
  </div>
  <div class="separator"></div>

  <app-mandat-detail-visites [mandat]="mandat" />
  <app-mandat-detail-bodacc [mandat]="mandat" />

  <div class="separator"></div>
  <app-mandat-detail-diffusion [mandat]="mandat" />
</div>
