import { CessionRubriqueInterface } from '@src/shared/cession-rubriques/interfaces/cession-rubriques.interface';

export const cessionRubriques: CessionRubriqueInterface[] = [
  { id: 61, desc: 'CHR', separat: 1, ordre: 1, id_cession_rubriques: 202 },
  {
    id: 11,
    desc: 'Bar - Brasserie - Tabac',
    separat: 0,
    ordre: 2,
    id_cession_rubriques: 26,
  },
  {
    id: 9,
    desc: 'Presse, Librairie',
    separat: 0,
    ordre: 3,
    id_cession_rubriques: 10,
  },
  {
    id: 39,
    desc: 'Creperie - Pizzeria',
    separat: 0,
    ordre: 5,
    id_cession_rubriques: 63,
  },
  {
    id: 17,
    desc: 'Hotel (+ restaurant)',
    separat: 0,
    ordre: 6,
    id_cession_rubriques: 32,
  },
  {
    id: 42,
    desc: 'Restaurant',
    separat: 0,
    ordre: 7,
    id_cession_rubriques: 66,
  },
  {
    id: 46,
    desc: 'Restauration rapide',
    separat: 0,
    ordre: 8,
    id_cession_rubriques: 72,
  },
  {
    id: 45,
    desc: 'Salon de the',
    separat: 0,
    ordre: 9,
    id_cession_rubriques: 71,
  },
  {
    id: 47,
    desc: 'Sandwicherie',
    separat: 0,
    ordre: 10,
    id_cession_rubriques: 73,
  },
  {
    id: 1,
    desc: 'COMMERCE - NEGOCE',
    separat: 1,
    ordre: 11,
    id_cession_rubriques: 1,
  },
  {
    id: 2,
    desc: 'Alimentation',
    separat: 0,
    ordre: 12,
    id_cession_rubriques: 17,
  },
  {
    id: 6,
    desc: 'Boucherie - Charcuterie',
    separat: 0,
    ordre: 15,
    id_cession_rubriques: 21,
  },
  {
    id: 7,
    desc: 'Boulangerie - Patisserie',
    separat: 0,
    ordre: 16,
    id_cession_rubriques: 22,
  },
  {
    id: 8,
    desc: 'Cadeaux Fleurs',
    separat: 0,
    ordre: 17,
    id_cession_rubriques: 23,
  },
  {
    id: 10,
    desc: 'Chaussures - Cuir ',
    separat: 0,
    ordre: 18,
    id_cession_rubriques: 25,
  },
  {
    id: 15,
    desc: 'Habillement - Textile',
    separat: 0,
    ordre: 19,
    id_cession_rubriques: 30,
  },
  {
    id: 18,
    desc: 'Informatique - Multimedia',
    separat: 0,
    ordre: 21,
    id_cession_rubriques: 33,
  },
  {
    id: 19,
    desc: 'Librairie - Papeterie',
    separat: 0,
    ordre: 22,
    id_cession_rubriques: 34,
  },
  {
    id: 21,
    desc: 'Mobilier - Decoration',
    separat: 0,
    ordre: 23,
    id_cession_rubriques: 36,
  },
  {
    id: 30,
    desc: 'Tabac - Presse',
    separat: 0,
    ordre: 24,
    id_cession_rubriques: 45,
  },
  { id: 32, desc: 'Traiteur', separat: 0, ordre: 25, id_cession_rubriques: 47 },
  {
    id: 35,
    desc: 'Divers commerces',
    separat: 0,
    ordre: 26,
    id_cession_rubriques: 50,
  },
  {
    id: 60,
    desc: 'INDUSTRIE / PRODUCTION / BATIMENT',
    separat: 1,
    ordre: 27,
    id_cession_rubriques: 201,
  },
  {
    id: 13,
    desc: 'Agriculture - Viticulture',
    separat: 0,
    ordre: 28,
    id_cession_rubriques: 28,
  },
  {
    id: 41,
    desc: 'Agro-Alimentaire',
    separat: 0,
    ordre: 29,
    id_cession_rubriques: 68,
  },
  {
    id: 22,
    desc: 'Mecanique - Metallurgie',
    separat: 0,
    ordre: 30,
    id_cession_rubriques: 37,
  },
  {
    id: 44,
    desc: 'Divers Industrie',
    separat: 0,
    ordre: 31,
    id_cession_rubriques: 70,
  },
  { id: 4, desc: 'BTP', separat: 0, ordre: 32, id_cession_rubriques: 19 },
  {
    id: 51,
    desc: 'Carrelage - Maconnerie',
    separat: 0,
    ordre: 33,
    id_cession_rubriques: 81,
  },
  {
    id: 23,
    desc: 'Charpente - Menuiserie',
    separat: 0,
    ordre: 34,
    id_cession_rubriques: 38,
  },
  {
    id: 12,
    desc: 'Electricité - Electronique',
    separat: 0,
    ordre: 35,
    id_cession_rubriques: 27,
  },
  {
    id: 52,
    desc: 'Peinture - Vitrerie - Platrerie',
    separat: 0,
    ordre: 36,
    id_cession_rubriques: 84,
  },
  {
    id: 24,
    desc: 'Plomberie - Chauffage',
    separat: 0,
    ordre: 37,
    id_cession_rubriques: 39,
  },
  {
    id: 62,
    desc: 'SERVICES',
    separat: 1,
    ordre: 39,
    id_cession_rubriques: 204,
  },
  { id: 49, desc: 'Conseil', separat: 0, ordre: 43, id_cession_rubriques: 76 },
  {
    id: 41,
    desc: 'Depannage - Reparation',
    separat: 0,
    ordre: 44,
    id_cession_rubriques: 65,
  },
  {
    id: 14,
    desc: 'Garage - Station service',
    separat: 0,
    ordre: 45,
    id_cession_rubriques: 29,
  },
  {
    id: 20,
    desc: 'Loisir -Tourisme',
    separat: 0,
    ordre: 46,
    id_cession_rubriques: 35,
  },
  {
    id: 25,
    desc: 'Nettoyage - Pressing',
    separat: 0,
    ordre: 47,
    id_cession_rubriques: 40,
  },
  {
    id: 54,
    desc: 'Pharmacie - Parapharmacie',
    separat: 0,
    ordre: 48,
    id_cession_rubriques: 87,
  },
  {
    id: 26,
    desc: 'Professions Liberales',
    separat: 0,
    ordre: 49,
    id_cession_rubriques: 41,
  },
  {
    id: 31,
    desc: 'Prestation Multimedia',
    separat: 0,
    ordre: 50,
    id_cession_rubriques: 46,
  },
  {
    id: 27,
    desc: 'Publicite',
    separat: 0,
    ordre: 51,
    id_cession_rubriques: 42,
  },
  {
    id: 28,
    desc: 'Sante - Optique',
    separat: 0,
    ordre: 52,
    id_cession_rubriques: 43,
  },
  { id: 56, desc: 'Taxi', separat: 0, ordre: 53, id_cession_rubriques: 95 },
  {
    id: 33,
    desc: 'Transport - Logistique',
    separat: 0,
    ordre: 54,
    id_cession_rubriques: 48,
  },
  {
    id: 57,
    desc: 'Entrepots - Logistique',
    separat: 0,
    ordre: 55,
    id_cession_rubriques: 96,
  },
  {
    id: 34,
    desc: 'Video - Photo',
    separat: 0,
    ordre: 56,
    id_cession_rubriques: 49,
  },
  {
    id: 29,
    desc: 'Divers services',
    separat: 0,
    ordre: 57,
    id_cession_rubriques: 44,
  },
  {
    id: 63,
    desc: 'IMMOBILIER PROFESSIONNEL',
    separat: 1,
    ordre: 58,
    id_cession_rubriques: 300,
  },
  { id: 36, desc: 'Bureaux', separat: 0, ordre: 59, id_cession_rubriques: 52 },
  {
    id: 37,
    desc: 'Immeubles Commerciaux / Mixtes',
    separat: 0,
    ordre: 60,
    id_cession_rubriques: 54,
  },
  {
    id: 58,
    desc: "Locaux d'activités - Entrepots",
    separat: 0,
    ordre: 61,
    id_cession_rubriques: 97,
  },
  {
    id: 59,
    desc: 'Locaux commerciaux - Boutiques',
    separat: 0,
    ordre: 62,
    id_cession_rubriques: 99,
  },
  {
    id: 38,
    desc: 'Terrain industriel et Agricole',
    separat: 0,
    ordre: 63,
    id_cession_rubriques: 57,
  },
];
