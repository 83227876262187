<div class="bodacc">
  <img
    alt="loi"
    height="30"
    ngSrc="../../../../../../../../assets/mandat-detail/loi.png"
    width="30" />
  <div>
    <p>Aucune information BODACC PC</p>
    <p>Aucune information BODACC</p>
  </div>
</div>
