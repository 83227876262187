import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatsFilterParams } from '@features/mandats/domain/entities/mandat-filter-params.interface';

export class MandatsFilter {
  static filterMandats(params: MandatsFilterParams): MandatDetailInterface[] {
    const NO_SEARCH_VALUE = 0;
    const NO_SEARCH_VALUE_STRING = '0';
    let filteredMandats = params.mandats;

    if (params.activityOption !== NO_SEARCH_VALUE) {
      filteredMandats = filteredMandats.filter(
        mandat => mandat.mandat.id_activite == params.activityOption
      );
    }

    if (params.legalformOption !== NO_SEARCH_VALUE) {
      filteredMandats = filteredMandats.filter(
        mandat => mandat.mandat.id_forme_juridique === params.legalformOption
      );
    }

    if (params.statusOptionValue !== NO_SEARCH_VALUE_STRING) {
      filteredMandats = filteredMandats.filter(
        mandat => mandat.mandat.etat === params.statusOptionValue
      );
    }

    if (params.selectedConsultant !== NO_SEARCH_VALUE) {
      filteredMandats = filteredMandats.filter(
        mandat => mandat.mandat.id_consultant === params.selectedConsultant
      );
    }

    if (params.minPrice > 0 || params.maxPrice < Infinity) {
      filteredMandats = filteredMandats.filter(
        mandat =>
          mandat.mandat.prix_de_presentation >= params.minPrice &&
          mandat.mandat.prix_de_presentation <= params.maxPrice
      );
    }

    if (params.minSurface > 0 || params.maxSurface < Infinity) {
      filteredMandats = filteredMandats.filter(
        mandat =>
          (mandat.salePoints?.surface_commerciale ?? 0) >= params.minSurface &&
          (mandat.salePoints?.surface_commerciale ?? 0) <= params.maxSurface
      );
    }

    if (params.checkboxValues.with) {
      filteredMandats = filteredMandats.filter(
        mandat => mandat.salePoints?.presence_extraction == true
      );
    }

    if (params.cityValue.length) {
      const cityValueLowerCase = params.cityValue.toLowerCase();
      filteredMandats = filteredMandats.filter(mandat =>
        String(mandat.salePoints?.locality?.toLowerCase()).includes(
          cityValueLowerCase
        )
      );
    }

    if (params.streetValue.length) {
      const streetValueLowerCase = params.streetValue.toLowerCase();
      filteredMandats = filteredMandats.filter(
        mandat =>
          String(mandat.salePoints?.route?.toLowerCase()).includes(
            streetValueLowerCase
          ) ||
          String(mandat.salePoints?.street_number).includes(
            streetValueLowerCase
          )
      );
    }

    if (params.searchValue.length) {
      const searchValueLowerCase = params.searchValue.toLowerCase();
      filteredMandats = filteredMandats.filter(mandat =>
        [
          String(mandat.salePoints?.route?.toLowerCase()),
          String(mandat.salePoints?.type_voie?.toLowerCase()),
          String(mandat.salePoints?.route?.toLowerCase()),
          String(mandat.salePoints?.postal_code),
          String(mandat.salePoints?.locality?.toLowerCase()),
          String(mandat.salePoints?.street_number),
          String(mandat.salePoints?.enseigne?.toLowerCase()),
        ].some(field => field.toLowerCase().includes(searchValueLowerCase))
      );
    }
    return filteredMandats;
  }

  static resetParams(mandat: MandatDetailInterface[]): MandatsFilterParams {
    const NO_SEARCH_VALUE = 0;
    const NO_SEARCH_VALUE_STRING = '0';
    return {
      mandats: mandat,
      activityOption: NO_SEARCH_VALUE,
      legalformOption: NO_SEARCH_VALUE,
      statusOptionValue: NO_SEARCH_VALUE_STRING,
      statusOption: NO_SEARCH_VALUE,
      selectedConsultant: NO_SEARCH_VALUE,
      minPrice: 0,
      maxPrice: Infinity,
      minSurface: 0,
      maxSurface: Infinity,
      checkboxValues: { with: false },
      cityValue: '',
      streetValue: '',
      searchValue: '',
    };
  }
}
