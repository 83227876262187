import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';
import {
  AddMandatToVisitForm,
  RemoveMandatFromVisitForm,
} from '@features/visit-form/domain/redux/actions/visit-form.actions';
import { VisitFormSelectors } from '@features/visit-form/domain/redux/selectors/visit-form.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

@Component({
  selector: 'app-mandat-detail-mobile-header',
  templateUrl: './mandat-detail-mobile-header.component.html',
  styleUrl: './mandat-detail-mobile-header.component.scss',
})
@UntilDestroy()
export class MandatDetailMobileHeaderComponent implements OnInit {
  @Input() salePointName!: string;
  @Input() idActivity!: number;
  @Input() mandat!: MandatDetailInterface;
  @Input() mandatState!: MandatStateEnum;
  mandatIds: number[] = [];
  protected readonly MandatStateEnum = MandatStateEnum;

  constructor(
    private location: Location,
    private store: Store
  ) {}

  ngOnInit() {
    this.store
      .select(VisitFormSelectors.mandatsIds)
      .pipe(untilDestroyed(this))
      .subscribe(ids => {
        this.mandatIds = ids;
      });
  }

  goBack(): void {
    this.location.back();
  }

  addMandatToListVisitForm(mandat: MandatDetailInterface) {
    if (!this.mandatIds.includes(mandat.mandat.id)) {
      this.store.dispatch(new AddMandatToVisitForm(mandat));
    } else {
      this.store.dispatch(new RemoveMandatFromVisitForm(mandat.mandat.id));
    }
  }
}
