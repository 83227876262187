<nav class="nav-header">
  <img
    (click)="goBack()"
    (keyup.enter)="goBack()"
    alt="Retour"
    height="20"
    ngSrc="../../../../../../../../assets/mandat-detail/arrow-left-white.svg"
    tabindex="0"
    width="20" />

  <app-activite-icons
    [activiteId]="this.idActivity"
    class="app-activite-icons"
    size="small"></app-activite-icons>

  <h1 class="title">{{ this.salePointName }}</h1>

  <span
    [matBadgeHidden]="!mandatIds.includes(mandat.mandat.id)"
    matBadge="V"
    matBadgeColor="primary"
    matBadgePosition="before"
    matBadgeSize="small">
    <img
      (click)="addMandatToListVisitForm(mandat)"
      (keyup.enter)="addMandatToListVisitForm(mandat)"
      *ngIf="
        mandatState !== MandatStateEnum.preMandat &&
        mandatState !== MandatStateEnum.judiciaryProcess &&
        mandatState !== MandatStateEnum.prospection
      "
      alt="cart icon"
      height="22"
      ngSrc="../../../../../../../../assets/mandat-detail/cart.svg"
      style="margin-left: 6px"
      tabindex="0"
      width="22" />
    <img
      *ngIf="mandatState === MandatStateEnum.prospection"
      alt="cart icon"
      height="22"
      ngSrc="../../../../../../../../assets/navbar/extract-w.png"
      style="margin-left: 6px"
      width="22" />
  </span>
</nav>
