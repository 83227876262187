export interface LegalFormInterface {
  id: number;
  parent_id: number;
  name: string;
}

export enum LegalFormEnum {
  fondsDeCommerce = 1,
  droitAuBail = 2,
  rechercheDeLocataire = 3,
  bienImmobilier = 4,
}
