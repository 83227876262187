import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BuyerDetailInterface } from '@features/buyers/domain/entities/buyers.interface';

import { ConsultantCivilite } from '@features/consultant/domain/entities/consultant.interface';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';
import { GooglePlacesAutocompleteComponent } from '@libs/google-places-autocomplete/google-places-autocomplete.component';
import { SelectItem } from '@libs/select/select-options';

@Component({
  selector: 'app-bailleur-contact',
  templateUrl: './bailleur-contact.component.html',
  styleUrl: './bailleur-contact.component.scss',
})
export class BailleurContactComponent {
  @Input({ required: true }) bailleurForm!: FormGroup;
  @ViewChild(GooglePlacesAutocompleteComponent)
  googlePlacesComponent!: GooglePlacesAutocompleteComponent;
  public civilitesItems: SelectItem[] = [
    {
      name: 'Mr',
      id: ConsultantCivilite.MONSIEUR,
      value: ConsultantCivilite.MONSIEUR,
    },
    {
      name: 'Mme',
      id: ConsultantCivilite.MADAME,
      value: ConsultantCivilite.MADAME,
    },
  ];

  onChange($event: AddressComponentInterface) {
    this.bailleurForm.patchValue($event);
  }

  onContactSelected($event: BuyerDetailInterface) {
    this.bailleurForm.patchValue({
      id: $event.id,
      prenom: $event.prenom,
      id_cabinet: $event.id_cabinet,
      nom: $event.nom,
      civilite: $event.civilite,
      email: $event.email,
      telephone: $event.telephone,
      mobile: $event.mobile,
      fax: $event.fax,
      place_id: $event.place_id,
      street_number: $event.street_number,
      indice: $event.indice,
      formatted_address: $event.formatted_address,
      lat: $event.lat,
      lng: $event.lng,
      url: $event.url,
      adresse_de_recherche: $event.adresse_de_recherche,
      type_voie: $event.type_voie,
      route: $event.route,
      comp_adresse: $event.comp_adresse,
      administrative_area_level_1: $event.administrative_area_level_1,
      administrative_area_level_2: $event.administrative_area_level_2,
      isNew: $event.isNew,
      postal_code: $event.postal_code,
      locality: $event.locality,
      contactConnu: $event.contactConnu,
      created_at: $event.created_at,
      updated_at: $event.updated_at,
      deleted_at: $event.deleted_at,
    });
    this.googlePlacesComponent.setInputValue($event.formatted_address);
  }
}
