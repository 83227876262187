import { FormSectionBase } from '@src/shared/forms/form.class';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';

export class MandatPredefinedForm extends FormSectionBase {
  private readonly isProspection: boolean;
  private readonly idCabinet: string;

  constructor(isProspection: boolean, idCabinet: string) {
    super();
    this.isProspection = isProspection;
    this.idCabinet = idCabinet;
  }

  createFormGroup(): FormGroup {
    if (!this.isProspection) {
      return new FormGroup({
        morale_or_physique_bailleur: new FormControl('', Validators.required),
        morale_or_physique_mandant: new FormControl(''),
        etat: new FormControl(MandatStateEnum.preMandat),
        id_cabinet: new FormControl(this.idCabinet),
      });
    } else {
      return new FormGroup({
        morale_or_physique_bailleur: new FormControl('', Validators.required),
        morale_or_physique_mandant: new FormControl(''),
        etat: new FormControl(MandatStateEnum.prospection),
        id_cabinet: new FormControl(this.idCabinet),
      });
    }
  }
}
