import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateGlobalMandatDto } from '@features/mandats/domain/dto/create-global-mandat.dto';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatInterface } from '@features/mandats/domain/entities/mandat.interface';
import { MandatApiPort } from '@features/mandats/domain/ports/api/mandat-api.port';
import { environment } from '@src/environments/environment';
import { EMPTY, expand, Observable, reduce, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivateMandatDto } from '@features/mandats/domain/dto/activate-mandat.dto';

@Injectable({
  providedIn: 'root',
})
export class MandatsApiService implements MandatApiPort {
  apiPath = environment.apiUrl;

  constructor(private http: HttpClient) {}

  /*  getMandats(): Observable<MandatDetailInterface[]> {
      return this.http.get<MandatDetailInterface[]>(`${this.apiPath}/mandats`);
    }*/

  getArchivedMandats(): Observable<MandatInterface[]> {
    return this.http.get<MandatInterface[]>(`${this.apiPath}/mandats-archives`);
  }

  //TODO VOIR POUR LE TYPE
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  createMandat(mandat: CreateGlobalMandatDto): Observable<any> {
    return this.http.post(`${this.apiPath}/mandats/create`, mandat);
  }

  getMandats(): Observable<MandatDetailInterface[]> {
    let page = 0;
    const offset = 2000;
    return this.loadPage(page, offset, 'mandatDetails').pipe(
      expand(mandats =>
        mandats.length < offset
          ? EMPTY
          : this.loadPage(++page, offset, 'mandatDetails')
      ),
      reduce(
        (acc: MandatDetailInterface[], mandats: MandatDetailInterface[]) =>
          acc.concat(mandats),
        [] as MandatDetailInterface[]
      ),
      map(mandats => {
        const uniqueMandatsSet = new Set();
        const uniqueMandats = [];
        for (const mandat of mandats) {
          const mandatStr = JSON.stringify(mandat);
          if (!uniqueMandatsSet.has(mandatStr)) {
            uniqueMandatsSet.add(mandatStr);
            uniqueMandats.push(mandat);
          }
        }
        return uniqueMandats;
      }),
      catchError(error => {
        console.error('Error in getMandat function: ', error);
        return throwError(() => error);
      })
    );
  }

  loadPage(
    page: number,
    offset: number,
    route: string
  ): Observable<MandatDetailInterface[]> {
    return this.http
      .get<
        MandatDetailInterface[]
      >(`${this.apiPath}${route}?page=${page}&offset=${offset}`)
      .pipe(
        catchError(error => {
          console.error('Error in loadPage function: ', error);
          return throwError(error);
        })
      );
  }

  activateMandat(body: ActivateMandatDto): Observable<any> {
    return this.http.post<any>(`${this.apiPath}/mandats/activate`, body);
  }
}
