import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormSectionBase } from '@src/shared/forms/form.class';
import { ExclusiviteEnum } from '@features/mandats/domain/entities/mandat.interface';

export class GeneralInformationsForm extends FormSectionBase {
  private readonly isProspection: boolean;

  constructor(isProspection: boolean) {
    super();
    this.isProspection = isProspection;
  }

  createFormGroup(): FormGroup {
    if (!this.isProspection) {
      return new FormGroup({
        id_consultant: new FormControl('', Validators.required),
        id_forme_juridique: new FormControl('', Validators.required),
        id_activite: new FormControl('', Validators.required),
        id_rubrique_cession_pme: new FormControl('', Validators.required),
        exclusivite: new FormControl(
          ExclusiviteEnum.non_exclusif,
          Validators.required
        ),
        etat_murs: new FormControl(''),
      });
    } else {
      return new FormGroup({
        id_consultant: new FormControl('', Validators.required),
        id_forme_juridique: new FormControl('', Validators.required),
        id_activite: new FormControl('', Validators.required),
        id_rubrique_cession_pme: new FormControl('', Validators.required),
        etat_murs: new FormControl(''),
      });
    }
  }
}
