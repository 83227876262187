import { FormControl, FormGroup } from '@angular/forms';
import { FormSectionBase } from '@src/shared/forms/form.class';

export class CompanieForm extends FormSectionBase {
  createFormGroup(): FormGroup {
    return new FormGroup({
      forme_juridique: new FormControl(null),
      adresse_ligne_1: new FormControl(null),
      adresse_ligne_2: new FormControl(null),
      code_postal: new FormControl(null),
      ville: new FormControl(null),
      complement_adresse: new FormControl(null),
      denomination: new FormControl(null),
      domaine_activite: new FormControl(null),
      indice_repetition: new FormControl(null),
      libelle_code_naf: new FormControl(null),
      libelle_voie: new FormControl(null),
      nic: new FormControl(null),
      nom: new FormControl(null),
      nom_entreprise: new FormControl(null),
      numero_voie: new FormControl(null),
      prenom: new FormControl(null),
      siren: new FormControl(null),
      siren_formate: new FormControl(null),
      type_search: new FormControl(null),
      type_voie: new FormControl(null),
    });
  }
}
