import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConsultantSelectors } from '@features/consultant/domain/redux/selectors/consultant.selectors';
import {
  ExclusiviteEnum,
  MandatWallEnum,
} from '@features/mandats/domain/entities/mandat.interface';
import { SelectItem } from '@libs/select/select-options';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { ActiviteInterface } from '@src/shared/activites/interfaces/activities.interface';
import { activitesObject } from '@src/shared/activites/objects/activities.object';
import { CessionRubriqueInterface } from '@src/shared/cession-rubriques/interfaces/cession-rubriques.interface';
import { cessionRubriques } from '@src/shared/cession-rubriques/objects/cession-rubriques.object';
import { LegalFormInterface } from '@src/shared/legal-form/interfaces/legal-form.interface';
import { legalFormObject } from '@src/shared/legal-form/objects/legal-form.objects';

import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrl: './general-information.component.scss',
})
export class GeneralInformationComponent implements OnInit {
  @Input({ required: true }) generalInformationForm!: FormGroup;
  @Select(ConsultantSelectors.getConsultantSelectItems)
  consultants$!: Observable<SelectItem[]>;
  consultants!: SelectItem[];
  public legalFormObject: LegalFormInterface[] = legalFormObject;
  public activitiesObject: ActiviteInterface[] = activitesObject;
  public rubriquesObject: CessionRubriqueInterface[] = cessionRubriques;
  public rubriquesItems!: SelectItem[];
  public wallStateItem!: SelectItem[];
  exclusifControl: FormControl<boolean | null> = new FormControl(false);

  ngOnInit() {
    this.consultants$.subscribe(consultants => {
      this.consultants = consultants;
    });
    this.rubriquesItems = this.rubriquesObject.map(rubrique => {
      return {
        id: rubrique.id,
        name: rubrique.desc,
      };
    });
    this.wallStateItem = [
      { id: MandatWallEnum.free, name: 'Libre' },
      { id: MandatWallEnum.busy, name: 'Occupé' },
      { id: MandatWallEnum.linked, name: 'Murs et fond' },
    ];
    this.exclusifControl.valueChanges.subscribe(value => {
      this.generalInformationForm
        .get('exclusivite')
        ?.setValue(
          value === true
            ? ExclusiviteEnum.exclusif
            : ExclusiviteEnum.non_exclusif
        );
    });
    this.generalInformationForm
      .get('id_forme_juridique')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetWallState();
      });
  }

  resetWallState() {
    this.generalInformationForm.get('etat_murs')?.setValue('');
  }
}
