import { Selector } from '@ngxs/store';
import {
  NavState,
  NavStateModel,
} from '@features/nav/domain/redux/state/nav.state';

export class NavSelectors {
  @Selector([NavState])
  static actualUrl(state: NavStateModel) {
    return state.actualUrl;
  }

  @Selector([NavState])
  static previousUrl(state: NavStateModel) {
    return state.previousUrl;
  }
}
