<div class="mandat-header">
  <app-btn
    *ngIf="!isProspection"
    [routerLink]="'/bon-de-visite'"
    color="secondary"
    icon="/assets/icons/user-add.svg"
    text="Créer un bon de visite" />

  <app-btn
    *ngIf="isProspection"
    color="secondary"
    icon="/assets/icons/user-add.svg"
    text="Exporter les infos" />

  <p class="mandats-length">
    {{ filteredMandatsLength }} / {{ mandatsLength }}
    <span *ngIf="!isProspection" class="mandats-length">mandats actifs</span
    ><span *ngIf="isProspection" class="mandats-length"
      >fiches de prospection</span
    >
  </p>
  <div class="right-container">
    <app-btn
      (click)="handleClickReset()"
      *ngIf="filteredMandatsLength < mandatsLength"
      color="ternary"
      text="Supprimer les filtres" />

    <app-btn
      *ngIf="!isProspection"
      [queryParams]="{ prospection: false }"
      [routerLink]="['/mandats/checking']"
      color="secondary"
      icon="/assets/icons/user-add.svg"
      text="Créer un pré-mandat" />
    <app-btn
      *ngIf="isProspection"
      [queryParams]="{ prospection: true }"
      [routerLink]="['/mandats/checking']"
      color="secondary"
      icon="/assets/icons/user-add.svg"
      text="Créer une fiche de prospection" />
  </div>
</div>
