import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MandatsSelectors } from '@features/mandats/domain/redux/selectors/mandats.selectors';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-sale-point',
  templateUrl: './sale-point.component.html',
  styleUrl: './sale-point.component.scss',
})
export class SalePointComponent implements OnInit, AfterViewInit {
  @Input({ required: true }) salePointForm!: FormGroup;
  @Select(MandatsSelectors.preSelectedAddress) preSelectedAddress$!: Observable<
    AddressComponentInterface | undefined
  >;
  preSelectedAddress: AddressComponentInterface | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.salePointForm
      .get('surface_commerciale')
      ?.valueChanges.subscribe(() => {
        this.calculateTotal();
      });
    this.salePointForm.get('surface_annexe')?.valueChanges.subscribe(() => {
      this.calculateTotal();
    });
    this.salePointForm.get('surface_bureaux')?.valueChanges.subscribe(() => {
      this.calculateTotal();
    });
    this.salePointForm.get('surface_terrasse')?.valueChanges.subscribe(() => {
      this.calculateTotal();
    });
  }

  ngAfterViewInit() {
    this.preSelectedAddress$.pipe(untilDestroyed(this)).subscribe(address => {
      if (address) {
        this.preSelectedAddress = address;
        this.salePointForm.patchValue({
          ...address,
        });
        this.cdr.detectChanges();
      }
    });
  }

  calculateTotal() {
    this.salePointForm
      .get('surface_totale')
      ?.setValue(
        +this.salePointForm.get('surface_commerciale')?.value +
          +this.salePointForm.get('surface_annexe')?.value +
          +this.salePointForm.get('surface_bureaux')?.value +
          +this.salePointForm.get('surface_terrasse')?.value
      );
  }

  updateValue(event: MatCheckboxChange, ControlName: string) {
    const newValue = event.checked ? 1 : 0;
    this.salePointForm.get(ControlName)?.setValue(newValue);
  }

  getControl(name: string): FormControl {
    return this.salePointForm.get(name) as FormControl;
  }
}
