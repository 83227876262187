import { Component, Input, OnInit } from '@angular/core';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { VisiteInterface } from '@features/visites/domain/models/visite-interface';
import { VisitesSelectors } from '@features/visites/domain/redux/visites.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'app-mandat-detail-visites',
  templateUrl: './mandat-detail-visites.component.html',
  styleUrl: './mandat-detail-visites.component.scss',
})
export class MandatDetailVisitesComponent implements OnInit {
  @Input() mandat!: MandatDetailInterface;
  visites: VisiteInterface[] | null = [];
  visitesIsShowed = false;
  protected readonly onfocus = onfocus;

  constructor(private store: Store) {}

  ngOnInit() {
    const mandat$ = this.store.select(
      VisitesSelectors.findVisiteByIdMandat(this.mandat?.mandat?.id)
    );
    mandat$.pipe(untilDestroyed(this)).subscribe(visites => {
      this.visites = visites;
    });
  }

  showVisites() {
    this.visitesIsShowed = !this.visitesIsShowed;
  }
}
