import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';
import {
  LoadSilenceMandats,
  SetSearchAddress,
  SetSearchCity,
} from '@features/mandats/domain/redux/actions/mandats.actions';
import { Select, Store } from '@ngxs/store';
import { MandatsSelectors } from '@features/mandats/domain/redux/selectors/mandats.selectors';
import { Observable } from 'rxjs';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NavSelectors } from '@features/nav/domain/redux/selectors/nav.selectors';
import { MandatsProspectionSelectors } from '@features/mandats/domain/redux/selectors/prospection.selectors';
import { ArchivedMandatsSelectors } from '@features/mandats/domain/redux/selectors/archived-mandats.selectors';
import { LoadSilenceArchivedMandats } from '@features/mandats/domain/redux/actions/archived-mandats.actions';

@UntilDestroy()
@Component({
  selector: 'app-mandats-container',
  templateUrl: './mandats-container.component.html',
  styleUrl: './mandats-container.component.scss',
})
export class MandatsContainerComponent implements OnInit {
  @Select(MandatsSelectors.mandats) mandats$!: Observable<
    MandatDetailInterface[]
  >;
  @Select(MandatsProspectionSelectors.mandats) prospectionMandats$!: Observable<
    MandatDetailInterface[]
  >;
  @Select(ArchivedMandatsSelectors.archivedMandats)
  archivedMandats$!: Observable<MandatDetailInterface[]>;
  @Select(NavSelectors.actualUrl) actualUrl$!: Observable<string>;

  mandats: MandatDetailInterface[] = [];
  mandatToDisplay!: MandatDetailInterface[];
  mandatShouldBeDisplayed = true;
  filtersActive = false;
  isProspection = false;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.dispatch([new LoadSilenceMandats()]);
    this.store.dispatch([new LoadSilenceArchivedMandats()]);
    this.mandats$.pipe(untilDestroyed(this)).subscribe(mandats => {
      this.mandats = mandats;
    });
    this.actualUrl$.pipe(untilDestroyed(this)).subscribe(url => {
      switch (url) {
        case '/mandats':
          this.mandats$.pipe(untilDestroyed(this)).subscribe(mandats => {
            this.mandats = mandats;
            this.isProspection = false;
          });
          break;
        case '/mandats/prospections':
          this.prospectionMandats$
            .pipe(untilDestroyed(this))
            .subscribe(mandats => {
              this.mandats = mandats;
              this.isProspection = true;
            });
          break;
        case '/mandats/archives':
          this.archivedMandats$
            .pipe(untilDestroyed(this))
            .subscribe(mandats => {
              this.mandats = mandats;
            });
          break;
        default:
          this.mandats = [];
          break;
      }
    });
    this.store.dispatch([new LoadSilenceMandats()]);
    this.store.dispatch([new LoadSilenceArchivedMandats()]);
  }

  updateMandatList(mandat: MandatDetailInterface[]): void {
    this.mandatToDisplay = this.sortMandats(mandat);
    this.cdr.detectChanges();
  }

  showMandat($event: boolean) {
    this.mandatShouldBeDisplayed = $event;
  }

  handleFiltersActiveChange(isActive: boolean) {
    this.filtersActive = isActive;
  }

  sortMandats(mandats: MandatDetailInterface[]): MandatDetailInterface[] {
    return mandats.sort((a, b) => {
      if (
        a.mandat.etat === MandatStateEnum.judiciaryProcess &&
        b.mandat.etat !== MandatStateEnum.judiciaryProcess
      ) {
        return -1;
      }
      if (
        b.mandat.etat === MandatStateEnum.judiciaryProcess &&
        a.mandat.etat !== MandatStateEnum.judiciaryProcess
      ) {
        return 1;
      }

      if (
        a.mandat.etat === MandatStateEnum.preMandat &&
        b.mandat.etat !== MandatStateEnum.preMandat
      ) {
        return -1;
      }
      if (
        b.mandat.etat === MandatStateEnum.preMandat &&
        a.mandat.etat !== MandatStateEnum.preMandat
      ) {
        return 1;
      }
      return 0;
    });
  }

  handleResetFilters() {
    const event = new Event('resetFilters');
    window.dispatchEvent(event);
    this.store.dispatch([new SetSearchCity(''), new SetSearchAddress('')]);
  }
}
