import { Component, Input, OnInit } from '@angular/core';
import { BodaccService } from '@features/bodacc/adapters/secondary/real/bodacc-api.service';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

@Component({
  selector: 'app-mandat-detail-bodacc',
  templateUrl: './mandat-detail-bodacc.component.html',
  styleUrl: './mandat-detail-bodacc.component.scss',
})
export class MandatDetailBodaccComponent implements OnInit {
  @Input() mandat!: MandatDetailInterface;

  constructor(private bodaccService: BodaccService) {}

  ngOnInit() {
    this.bodaccService.getBodaccVente().subscribe(data => {});
  }
}
