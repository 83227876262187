<div class="card">
  <div class="mandant-item">
    <img
      *ngIf="contact.civilite !== 'mrs'"
      alt="Homme d'affaire"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/homme.png"
      width="20" />
    <img
      *ngIf="contact.civilite === 'mrs'"
      alt="Femme d'affaire"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/femme.png"
      width="20" />
    <div *ngIf="contact.nom">
      <p *ngIf="contact.nom">{{ contact.nom }} {{ contact.prenom }}</p>
      <p *ngIf="!contact.nom">Aucune donnée disponible</p>
    </div>
    <div *ngIf="!contact.nom">
      <p>Aucune donnée disponible</p>
    </div>
  </div>
  <div class="mandant-item">
    <img
      alt="emplacement"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/place.png"
      width="20" />
    <p *ngIf="contact.locality">
      {{ contact.street_number }} {{ contact.route | uppercase }}
      {{ contact.comp_adresse | uppercase }} {{ contact.postal_code }}
      {{ contact.locality | uppercase }}

      <img
        *ngIf="contact.place_id"
        alt="epinglé"
        alt="geolocalisation"
        height="12"
        height="12"
        ngSrc="../../../../../../../../assets/mandat-detail/map-pin.png"
        ngSrc="../../../../../../../../assets/icons/geo-green.png"
        width="12" />
      <img
        *ngIf="!contact.place_id"
        alt="geolocalisation"
        height="12"
        ngSrc="assets/icons/geo-red.png"
        width="12" />
    </p>

    <p *ngIf="!contact.locality">Aucune donnée disponible</p>
  </div>
  <div class="mandant-item">
    <img
      alt="téléphone"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/phone.png"
      width="20" />
    <p>{{ checkData(contact.mobile) }}</p>
  </div>
  <div class="mandant-item">
    <img
      alt="email"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/mail.png"
      width="20" />
    <a href="mailto:{{ contact.email }}">{{ checkData(contact.email) }}</a>
  </div>
</div>
