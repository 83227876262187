<form [formGroup]="priceForm" class="create-form">
  <app-input-slide
    [required]="true"
    formControlName="prix_de_presentation"
    inputType="price"
    placeholder="Prix de présentation"
    type="number"
    width="fit" />
  <app-input-slide
    formControlName="honoraires_vendeur"
    inputType="price"
    placeholder="Honoraires charge vendeur"
    type="number"
    width="fit" />
  <app-input-slide
    formControlName="honoraires_acquereur"
    inputType="price"
    placeholder="Honoraires charge acquéreur"
    type="number"
    width="fit" />
  <div class="form-group">
    <label for="honoraire-tva">T.V.A sur honoraires</label>
    <app-select
      [items]="tvaItems"
      formControlName="option_tva"
      id="honoraire-tva"
      size="fit" />
  </div>
</form>
