import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MandatStateEnum,
  TvaExprimeEnum,
} from '@features/mandats/domain/entities/mandat.interface';
import { FormSectionBase } from '@src/shared/forms/form.class';

export class PriceForm extends FormSectionBase {
  private isProspection: boolean;
  private mandatEtat?: MandatStateEnum; // Rendre mandatEtat optionnel
  private tvaExprime: TvaExprimeEnum;

  constructor(isProspection: boolean, mandatEtat?: MandatStateEnum) {
    // Paramètre optionnel
    super();
    this.isProspection = isProspection;
    this.mandatEtat = mandatEtat;
    this.tvaExprime = TvaExprimeEnum.exprimes_ht;
  }

  createFormGroup(): FormGroup {
    // Si mandatEtat est undefined ou s'il n'est pas verrouillé, traiter comme le dernier cas
    if (this.mandatEtat && this.isMandatPriceLocked(this.mandatEtat)) {
      return new FormGroup({
        prix_de_presentation: new FormControl({ value: '', disabled: true }),
        honoraires_acquereur: new FormControl(''),
        honoraires_vendeur: new FormControl(''),
        option_tva: new FormControl(this.tvaExprime),
        droits_mutation: new FormControl({ value: '', disabled: true }),
      });
    } else if (this.isProspection) {
      return new FormGroup({
        prix_de_presentation: new FormControl(''),
        honoraires_acquereur: new FormControl(''),
        honoraires_vendeur: new FormControl(''),
        option_tva: new FormControl(this.tvaExprime),
        droits_mutation: new FormControl(''),
      });
    } else {
      return new FormGroup({
        prix_de_presentation: new FormControl('', Validators.required),
        honoraires_acquereur: new FormControl(''),
        honoraires_vendeur: new FormControl(''),
        option_tva: new FormControl(this.tvaExprime),
        droits_mutation: new FormControl(''),
      });
    }
  }

  private isMandatPriceLocked(mandatEtat: MandatStateEnum): boolean {
    const etatsValid: MandatStateEnum[] = [
      MandatStateEnum.active,
      MandatStateEnum.underOffer,
      MandatStateEnum.underCompromise,
    ];

    return etatsValid.includes(mandatEtat);
  }
}
