import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BodaccService {
  private apiUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getBodaccVente() {
    return this.http.get(`${this.apiUrl}//bodacc-cessions-ventes/v2Vente`);
  }
}
