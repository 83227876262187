import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormSectionBase } from '@src/shared/forms/form.class';

export class SalePointForm extends FormSectionBase {
  private isProspection: boolean;

  constructor(isProspection: boolean) {
    super();
    this.isProspection = isProspection;
  }

  createFormGroup(): FormGroup {
    if (!this.isProspection) {
      return new FormGroup({
        id: new FormControl(0),
        enseigne: new FormControl('', Validators.required),
        formatted_address: new FormControl('', Validators.required),
        locality: new FormControl(0),
        postal_code: new FormControl(0),
        echeance_bail: new FormControl(''),
        surface_commerciale: new FormControl('', Validators.required),
        surface_annexe: new FormControl(''),
        surface_bureaux: new FormControl(''),
        surface_terrain: new FormControl(''),
        surface_terrasse: new FormControl(''),
        surface_totale: new FormControl('', Validators.required),
        lineaire: new FormControl(''),
        presence_extraction: new FormControl(0),
        quai_dechargement: new FormControl(0),
        copropriete: new FormControl(0),
        accessibilite_pmr: new FormControl(0),
        acces_pl: new FormControl(0),
        place_id: new FormControl(''),
        administrative_area_level_1: new FormControl(''),
        administrative_area_level_2: new FormControl(''),
        comp_adresse: new FormControl(''),
        country: new FormControl(''),
        route: new FormControl(''),
        street_number: new FormControl(''),
        indice: new FormControl(''),
        url: new FormControl(''),
        latitude: new FormControl(''),
        longitude: new FormControl(''),
      });
    } else {
      return new FormGroup({
        id: new FormControl(0),
        enseigne: new FormControl('', Validators.required),
        formatted_address: new FormControl('', Validators.required),
        locality: new FormControl(''),
        postal_code: new FormControl(''),
        echeance_bail: new FormControl(''),
        surface_commerciale: new FormControl(''),
        surface_annexe: new FormControl(''),
        surface_bureaux: new FormControl(''),
        surface_terrain: new FormControl(''),
        surface_terrasse: new FormControl(''),
        surface_totale: new FormControl(''),
        lineaire: new FormControl(''),
        presence_extraction: new FormControl(0),
        quai_dechargement: new FormControl(0),
        copropriete: new FormControl(0),
        accessibilite_pmr: new FormControl(0),
        acces_pl: new FormControl(0),
        place_id: new FormControl(''),
        administrative_area_level_1: new FormControl(''),
        administrative_area_level_2: new FormControl(''),
        comp_adresse: new FormControl(''),
        country: new FormControl(''),
        route: new FormControl(''),
        street_number: new FormControl(''),
        indice: new FormControl(''),
        url: new FormControl(''),
        latitude: new FormControl(''),
        longitude: new FormControl(''),
      });
    }
  }
}
