import { FormControl, FormGroup, Validators } from '@angular/forms';
import { internationalPhoneValidator } from '@src/shared/forms/custom-validator/phone-number.validator';
import { FormSectionBase } from '@src/shared/forms/form.class';

export class ContactForm extends FormSectionBase {
  private idCabinet: string;
  private isBailleur: boolean;

  constructor(idCabinet: string, isBailleur: boolean = false) {
    super();
    this.idCabinet = idCabinet;
    this.isBailleur = isBailleur;
  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(''),
      civilite: new FormControl(
        '',
        this.isBailleur ? null : Validators.required
      ),
      nom: new FormControl('', this.isBailleur ? null : Validators.required),
      prenom: new FormControl('', this.isBailleur ? null : Validators.required),
      mobile: new FormControl(
        '',
        this.isBailleur ? null : [internationalPhoneValidator()]
      ),
      telephone: new FormControl(''),
      email: new FormControl('', this.isBailleur ? null : [Validators.email]),
      contact: new FormControl(''),
      administrative_area_level_1: new FormControl(''),
      administrative_area_level_2: new FormControl(''),
      comp_adresse: new FormControl(''),
      street_number: new FormControl(''),
      place_id: new FormControl(''),
      postal_code: new FormControl(''),
      formatted_address: new FormControl(''),
      route: new FormControl(''),
      locality: new FormControl(''),
      url: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
      is_creation: new FormControl(false),
      id_cabinet: new FormControl(this.idCabinet),
    });
  }
}
