import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShortPappersCompanyInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies-short.interface';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PappersService {
  constructor(private http: HttpClient) {}

  /**
   * Recherche des entreprises par nom et numéro SIREN, puis retourne un tableau unique de résultats sans doublons.
   *
   * @param {string} term - Terme de recherche pour les entreprises.
   * @returns {Observable<ShortResult[]>} - Observable du tableau de résultats sans doublons d'entreprises ayant le
   *     même numéro SIREN.
   */

  suggestionUnitelegale(
    term: string
  ): Observable<ShortPappersCompanyInterface[]> {
    try {
      const url = `https://suggestions.pappers.fr/v2?q=${encodeURIComponent(term)}&cibles=nom_entreprise,siren`;
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.http.get<any>(url).pipe(
        map(response => {
          const companies = response;
          if (
            companies.resultats_nom_entreprise.length === 0 &&
            companies.resultats_siren.length === 0
          ) {
            return [];
          }
          const mappedNomEntreprise = companies.resultats_nom_entreprise.map(
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            (company: any) =>
              this.mapCompanyToShortResult(company, 'searchByRs')
          );
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          const mappedSiren = companies.resultats_siren.map((company: any) =>
            this.mapCompanyToShortResult(company, 'searchBySiren')
          );
          const mergedCompanies = [...mappedNomEntreprise, ...mappedSiren];
          return this.removeDuplicateSiren(mergedCompanies);
        }),
        catchError(err => {
          console.log(err);
          return throwError(() => err); // modification here
        })
      );
    } catch (err) {
      console.log(err);
      return throwError(() => err); // and here
    }
  }

  private mapCompanyToShortResult(
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    company: any,
    type_search: string
  ): ShortPappersCompanyInterface {
    return {
      nom: company.nom,
      prenom: company.prenom,
      siren: company.siren,
      siren_formate: company.siren_formate,
      denomination: company.denomination,
      nom_entreprise: company.nom_entreprise,
      forme_juridique: company.forme_juridique,
      libelle_code_naf: company.libelle_code_naf,
      type_search,
      nic: company.siege.nic,
      numero_voie: company.siege.numero_voie,
      indice_repetition: company.siege.indice_repetition,
      type_voie: company.siege.type_voie,
      libelle_voie: company.siege.libelle_voie,
      complement_adresse: company.siege.complement_adresse,
      adresse_ligne_1: company.siege.adresse_ligne_1,
      adresse_ligne_2: company.siege.adresse_ligne_2,
      code_postal: company.siege.code_postal,
      ville: company.siege.ville,
      domaine_activite: company.domaine_activite,
    };
  }

  /* Cette fonction supprime les valeurs de sirènes dupliquées du tableau des entreprises en parcourant en boucle les entreprises fournies et en les poussant vers le tableau uniqueCompanies uniquement si leur valeur de sirène n'a pas déjà été ajoutée au sirenSet. */
  private removeDuplicateSiren(
    companies: ShortPappersCompanyInterface[]
  ): ShortPappersCompanyInterface[] {
    const uniqueCompanies: ShortPappersCompanyInterface[] = [];
    const sirenSet: Set<string> = new Set<string>();
    companies.forEach(company => {
      if (!sirenSet.has(company.siren)) {
        sirenSet.add(company.siren);
        uniqueCompanies.push(company);
      }
    });
    return uniqueCompanies;
  }
}
