import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CitySearchInterface } from '@libs/city-search/city-search.interface';
import { CitiesService } from '@libs/city-search/cities.service';
import { IdGeneratoreService } from '@src/shared/id-generator/id-generatore.service';

@Component({
  selector: 'app-city-search',
  templateUrl: './city-search.component.html',
  styleUrls: ['./city-search.component.scss'],
})
export class CitySearchComponent implements OnInit {
  @Input() displayedValue!: string;
  @Output() cityChange: EventEmitter<CitySearchInterface> = new EventEmitter();
  @Input() isCreation!: boolean;
  @Input() forDesktop = false;
  @Input() required = false;
  public inputId = this.idGeneratorService.generateShortenedId();
  isInputFocused!: boolean;
  isTouched = false;
  searchResults: CitySearchInterface[] = [];
  searchSubject = new Subject<string>();

  constructor(
    private citiesService: CitiesService,
    private cdr: ChangeDetectorRef,
    private idGeneratorService: IdGeneratoreService
  ) {}

  ngOnInit() {
    this.searchSubject
      .pipe(
        debounceTime(100),
        switchMap(term => this.citiesService.search(term))
      )
      .subscribe(results => {
        this.searchResults = results.slice(0, 8);
      });
  }

  onSearch(term: string) {
    this.searchSubject.next(term);
  }

  onSelectCity(city: CitySearchInterface) {
    this.cityChange.emit(city);
    this.displayedValue = city.ville + ' - ' + city.postal_code;
    this.searchResults = [];
  }

  checkIfValueExist(): boolean {
    return this.displayedValue !== '';
  }

  onFocus(b: boolean) {
    this.isInputFocused = b;
    this.isTouched = true;
    this.cdr.detectChanges();
  }
}
