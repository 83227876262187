import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ConsultantSelectors } from '@features/consultant/domain/redux/selectors/consultant.selectors';
import { MandatsFilter } from '@features/mandats/domain/classes/mandat-filter.class';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatsFilterParams } from '@features/mandats/domain/entities/mandat-filter-params.interface';
import {
  activeMandatStateObject,
  MandatStateInterface,
} from '@features/mandats/domain/entities/mandat.interface';
import {
  SetSearchAddress,
  SetSearchCity,
} from '@features/mandats/domain/redux/actions/mandats.actions';
import { MandatsSelectors } from '@features/mandats/domain/redux/selectors/mandats.selectors';
import { SelectItem } from '@libs/select/select-options';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { ActiviteInterface } from '@src/shared/activites/interfaces/activities.interface';
import { activitesObject } from '@src/shared/activites/objects/activities.object';
import { LegalFormInterface } from '@src/shared/legal-form/interfaces/legal-form.interface';
import { legalFormObject } from '@src/shared/legal-form/objects/legal-form.objects';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-mandat-search-tab',
  templateUrl: './mandat-search-tab.component.html',
  styleUrl: './mandat-search-tab.component.scss',
})
export class MandatSearchTabComponent implements OnChanges, OnInit {
  @Input() mandats!: MandatDetailInterface[];
  @Input() isProspection!: boolean;
  @Output() filteredMandatsChange = new EventEmitter<MandatDetailInterface[]>();
  @Output() hideMandat = new EventEmitter<boolean>();
  @Output() showMandat = new EventEmitter<boolean>();
  @Output() filtersActiveChange = new EventEmitter<boolean>();
  @Select(ConsultantSelectors.getConsultantSelectItems)
  consultants$!: Observable<SelectItem[]>;
  @Select(MandatsSelectors.searchAddress)
  searchAddress$!: Observable<string>;
  @Select(MandatsSelectors.searchCity)
  searchCity$!: Observable<string>;
  searchAddress!: string;
  consultants: SelectItem[] = [{ name: '-- Tous --', id: 0 }];
  activeFilterCount = 0;
  showStatus = true;
  public readonly NO_SEARCH_VALUE = 0;
  public readonly NO_SEARCH_VALUE_STRING = '0';
  public activitesObject: ActiviteInterface[] = [
    { id: 0, name: '-- Tous --', iconsPath: '', color: '' },
    ...activitesObject,
  ];
  public legalFormObject: LegalFormInterface[] = [
    { id: 0, name: '-- Tous --', parent_id: 0 },
    ...legalFormObject,
  ];
  public activeMandatStateObject: MandatStateInterface[] = [
    { id: 0, name: '-- Tous --', value: '0' },
    ...activeMandatStateObject,
  ];
  params: MandatsFilterParams;

  filteredMandats!: MandatDetailInterface[];
  showOtherFilters = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {
    this.consultants$.pipe(untilDestroyed(this)).subscribe(consultants => {
      if (this.consultants.length <= 1) {
        this.consultants = [...this.consultants, ...consultants];
      }
    });

    this.params = {
      mandats: this.mandats ?? [],
      activityOption: this.NO_SEARCH_VALUE,
      legalformOption: this.NO_SEARCH_VALUE,
      statusOptionValue: this.NO_SEARCH_VALUE_STRING,
      statusOption: this.NO_SEARCH_VALUE,
      selectedConsultant: this.NO_SEARCH_VALUE,
      minPrice: 0,
      maxPrice: Infinity,
      minSurface: 0,
      maxSurface: Infinity,
      checkboxValues: { with: false },
      cityValue: '',
      streetValue: '',
      searchValue: '',
    };
  }

  get rechercheText(): string {
    return `Rechercher (${this.filteredMandats.length})`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mandats']) {
      this.filteredMandats = this.mandats;
      this.params.mandats = this.mandats;
      this.filterMandats();
    }
  }

  ngOnInit() {
    window.addEventListener('resetFilters', () => {
      this.resetFilters();
    });
    this.store.dispatch([new SetSearchAddress(''), new SetSearchCity('')]);

    this.showStatus = !this.isProspection;
    this.searchAddress$.pipe(untilDestroyed(this)).subscribe(address => {
      this.params.streetValue = address;
      this.filterMandats();
    });
    this.searchCity$.pipe(untilDestroyed(this)).subscribe(city => {
      this.params.cityValue = city;
      this.filterMandats();
    });
  }

  filterMandats() {
    this.filteredMandats = MandatsFilter.filterMandats(this.params);
    this.filteredMandatsChange.emit(this.filteredMandats);
    this.updateFiltersActiveStatus();
    this.activeFilterCount = this.countActiveFilters();
    this.cdr.detectChanges();
  }

  onActivityChange(value: number | string) {
    this.params.activityOption = Number(value);
    this.filterMandats();
  }

  onLegalformChange(value: number | string) {
    this.params.legalformOption = Number(value);
    this.filterMandats();
  }

  onStatusChange(value?: SelectItem) {
    this.params.statusOptionValue = String(value?.value);
    this.params.statusOption = Number(value?.id);
    this.filterMandats();
  }

  onMinPriceChange(value: number) {
    this.params.minPrice = Number(value);
    this.filterMandats();
  }

  onMaxPriceChange(value: number) {
    if (value == 0) {
      this.params.maxPrice = Infinity;
    } else {
      this.params.maxPrice = Number(value);
    }
    this.filterMandats();
  }

  onMinSurfaceChange(value: number) {
    this.params.minSurface = Number(value);
    this.filterMandats();
  }

  onMaxSurfaceChange(value: number) {
    if (value == 0) {
      this.params.maxSurface = Infinity;
    } else {
      this.params.maxSurface = Number(value);
    }
    this.filterMandats();
  }

  onSearchChange(value: string) {
    this.params.searchValue = value;
    this.filterMandats();
  }

  onCityChange(value: string) {
    this.params.cityValue = value;
    this.filterMandats();
  }

  onStreetChange(value: string) {
    this.params.streetValue = value;
    this.filterMandats();
  }

  toggleOtherFilters() {
    this.showOtherFilters = !this.showOtherFilters;
    if (this.showOtherFilters) {
      this.showMandat.emit(false);
    } else if (!this.showOtherFilters) {
      this.showMandat.emit(true);
    }
    this.cdr.detectChanges();
  }

  onCheckboxChange(choice: string) {
    if (choice === 'with') {
      this.params.checkboxValues.with = true;
    } else {
      this.params.checkboxValues.with = false;
    }
    this.filterMandats();
  }

  onConsultantChange(value: number) {
    this.params.selectedConsultant = value;
    this.filterMandats();
  }

  resetFilters() {
    this.params = MandatsFilter.resetParams(this.params.mandats);
    this.filterMandats();
    this.cdr.detectChanges();
  }

  areFiltersActive(): boolean {
    return (
      this.params.activityOption !== this.NO_SEARCH_VALUE ||
      this.params.legalformOption !== this.NO_SEARCH_VALUE ||
      this.params.statusOptionValue !== this.NO_SEARCH_VALUE_STRING ||
      this.params.minPrice > 0 ||
      this.params.maxPrice < Infinity ||
      this.params.minSurface > 0 ||
      this.params.maxSurface < Infinity ||
      this.params.searchValue.length > 0 ||
      this.params.checkboxValues.with
    );
  }

  updateFiltersActiveStatus() {
    const isActive = this.areFiltersActive();
    this.filtersActiveChange.emit(isActive);
  }

  countActiveFilters(): number {
    let activeFilterCount = 0;
    if (this.params.activityOption !== this.NO_SEARCH_VALUE)
      activeFilterCount++;
    if (this.params.legalformOption !== this.NO_SEARCH_VALUE)
      activeFilterCount++;
    if (this.params.statusOptionValue !== this.NO_SEARCH_VALUE_STRING)
      activeFilterCount++;
    if (this.params.minPrice > 0) activeFilterCount++;
    if (this.params.maxPrice < Infinity) activeFilterCount++;
    if (this.params.minSurface > 0) activeFilterCount++;
    if (this.params.maxSurface < Infinity) activeFilterCount++;
    if (this.params.searchValue.length > 0) activeFilterCount++;
    if (this.params.checkboxValues.with) activeFilterCount++;
    return activeFilterCount;
  }
}
