import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appMandatStateColor]',
  standalone: true,
})
export class MandatStateColorDirective implements OnChanges {
  @Input() appMandatStateColor!: string;
  @Input() isChecking?: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    const defaultColor = this.isChecking ? 'black' : 'grey';
    this.changeColor(this.appMandatStateColor, defaultColor);
  }

  private changeColor(value: string, defaultColor: string): void {
    let color = '';
    switch (value) {
      case 'Procédure collective':
        color = 'red';
        break;
      case 'Pré-mandat':
        color = 'green';
        break;
      default:
        color = defaultColor;
    }
    this.el.nativeElement.style.color = color;
  }
}
