<div
  [ngClass]="{
    'input-focused': checkIfValueExist() || isInputFocused,
    desktop: forDesktop,
    'input-invalid': isTouched && required && !checkIfValueExist()
  }"
  class="input_slide-container">
  <input
    (blur)="onFocus(false)"
    (focus)="onFocus(true)"
    (input)="onSearch(displayedValue)"
    [(ngModel)]="displayedValue"
    [id]="inputId"
    [matAutocomplete]="auto"
    placeholder=""
    type="text" />
  <label
    [class.required]="required"
    [for]="inputId"
    [ngClass]="{ 'error-label': isTouched && required }"
    class="placeholder"
    >Ville de diffusion</label
  >
</div>
<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="onSelectCity($event.option.value)">
  <mat-option *ngFor="let city of searchResults" [value]="city">
    {{ city.ville }} - {{ city.postal_code }}
  </mat-option>
</mat-autocomplete>
