import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'booleanToYesOrNo' })
export class BooleanToYesOrNoPipe implements PipeTransform {
  transform(value: boolean | string | number): string {
    if (typeof value === 'string') {
      return value === '0' ? 'Non' : 'Oui';
    }
    return value ? 'Oui' : 'Non';
  }
}
