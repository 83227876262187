import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import {
  AddAddressToMandatSelection,
  RemoveAddressFromMandatSelection,
} from '@features/mandats/domain/redux/actions/mandats.actions';

import { MandatsSelectors } from '@features/mandats/domain/redux/selectors/mandats.selectors';
import { MandatsProspectionSelectors } from '@features/mandats/domain/redux/selectors/prospection.selectors';
import { AddressComponentInterface } from '@libs/google-places-autocomplete/formatted-address.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-checking-sale-point',
  templateUrl: './checking-sale-point.component.html',
  styleUrl: './checking-sale-point.component.scss',
})
export class CheckingSalePointComponent implements OnInit {
  matchingMandats: MandatDetailInterface[] = [];
  searchExecuted = false;
  addresReceivedFromGoogle!: AddressComponentInterface;
  isProspection = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.store.dispatch(new RemoveAddressFromMandatSelection());
    this.route.queryParams.subscribe(params => {
      this.isProspection = params['prospection'] === 'true';
    });
  }

  checkExistingSalePoint($event: AddressComponentInterface) {
    this.addresReceivedFromGoogle = $event;
    const mandat$ = this.store.select(
      MandatsSelectors.findMandatByAddress($event)
    );
    const mandatProspection$ = this.store.select(
      MandatsProspectionSelectors.findMandatByAddress($event)
    );
    combineLatest([mandat$, mandatProspection$])
      .pipe(
        untilDestroyed(this),
        map(([mandats, mandatsProspection]) => {
          return [...mandats, ...mandatsProspection];
        })
      )
      .subscribe(matchingMandats => {
        this.matchingMandats = matchingMandats;
        this.searchExecuted = true;
      });
  }

  logMandat() {
    this.store.dispatch(
      new AddAddressToMandatSelection(this.addresReceivedFromGoogle)
    );
  }
}
