<form [formGroup]="bailleurForm" class="create-form">
  <div class="civilite">
    <label class="required" for="civilite">Civilité </label>

    <app-select
      [items]="civilitesItems"
      class="form-control"
      formControlName="civilite"
      id="civilite"
      size="small" />
  </div>
  <div class="form-group">
    <app-select-contact
      (optionSelectedEvent)="onContactSelected($event)"
      [required]="true"
      class="form-control"
      formControlName="nom"
      placeholder="Nom"
      width="fit"></app-select-contact>
  </div>

  <div class="form-group">
    <app-input-slide
      class="form-control"
      formControlName="prenom"
      id="prenom"
      inputType="text"
      placeholder="Prénom"
      width="fit" />
  </div>
  <div class="form-group">
    <app-input-slide
      class="form-control"
      formControlName="mobile"
      id="mobile"
      inputType="text"
      placeholder="Téléphone mobile"
      width="fit" />
  </div>
  <div class="form-group">
    <app-input-slide
      class="form-control"
      formControlName="telephone"
      id="fixe"
      inputType="text"
      placeholder="Téléphone fixe"
      width="fit" />
  </div>
  <div class="form-group">
    <app-input-slide
      class="form-control"
      formControlName="email"
      id="email"
      inputType="text"
      placeholder="Email"
      width="fit" />
  </div>

  <div class="form-group">
    <app-google-places-autocomplete
      (formattedAddress)="onChange($event)"
      placeholder="Adresse" />
  </div>
</form>
