<div class="sale-point-search-container">
  <h1>Adresse du point de vente</h1>
  <div class="search">
    <p class="search-text">
      Vérification des fiches pré-existantes à cette adresse.
    </p>

    <app-google-places-autocomplete
      (formattedAddress)="checkExistingSalePoint($event)"
      [placeholder]="
        'Rechercher une adresse '
      "></app-google-places-autocomplete>

    <div *ngIf="searchExecuted && matchingMandats.length > 0" class="result">
      <p class="result-quantity">
        Il existe {{ matchingMandats.length }} point(s) de vente(s) à cette
        adresse.
      </p>
      <div *ngFor="let mandat of matchingMandats" class="search-item">
        <p
          (click)="logMandat()"
          (keyup.enter)="logMandat()"
          [routerLink]="['/mandats/details', mandat.mandat.id]"
          class="enseigne"
          role="button"
          tabindex="0">
          {{ mandat.salePoints.enseigne }} -
          <span
            [appMandatStateColor]="mandat | mandatStateDisplayer"
            [isChecking]="true">
            {{ mandat | mandatStateDisplayer }}</span
          >
          <br />
          <span class="color-grey">
            {{ mandat.salePoints.street_number }} {{ mandat.salePoints.route }}
            {{ mandat.salePoints.comp_adresse }}
            {{ mandat.salePoints.postal_code }}
            {{ mandat.salePoints.locality }}</span
          >
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="searchExecuted" class="create">
    <p *ngIf="matchingMandats.length === 0">
      Aucun point de vente trouvé à cette adresse
    </p>
    <app-btn
      (click)="logMandat()"
      *ngIf="!isProspection"
      [queryParams]="{ prospection: false }"
      [routerLink]="'/mandats/create'"
      color="primary"
      size="large"
      text="Créer un nouveau mandat" />
    <app-btn
      (click)="logMandat()"
      *ngIf="isProspection"
      [queryParams]="{ prospection: true }"
      [routerLink]="'/mandats/create'"
      color="primary"
      size="large"
      text="Créer une fiche de prospection" />
  </div>
</div>
