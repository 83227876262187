// set actual url

export class SetActualUrl {
  static readonly type = '[Nav] Set actual url';

  constructor(public url: string) {}
}

export class SetPreviousUrl {
  static readonly type = '[Nav] Set previous url';

  constructor(public url: string) {}
}
