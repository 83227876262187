import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationSelectors } from '@features/authentification/domain/redux/selectors/authentication.selectors';
import { ContactInterface } from '@features/contacts/domain/entities/contacts.interface';
import {
  CreateGlobalMandatDto,
  CreateMandatDto,
} from '@features/mandats/domain/dto/create-global-mandat.dto';
import {
  MandatWallEnum,
  MoraleOrPhysiqueEnum,
} from '@features/mandats/domain/entities/mandat.interface';
import { MandatValidators } from '@features/mandats/domain/forms-validators/mandat-validators';
import { SalePointValidators } from '@features/mandats/domain/forms-validators/sale-point-validators';
import { CreateMandat } from '@features/mandats/domain/redux/actions/mandats.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CompanieForm } from '@src/shared/forms/companies/companies.form';
import { ContactForm } from '@src/shared/forms/contact/contact.form';
import { MandatAnnonceForm } from '@src/shared/forms/mandat/mandat-annonce.form';
import { MandatCommentaireForm } from '@src/shared/forms/mandat/mandat-commentaire.form';
import { MandatDiffusionForm } from '@src/shared/forms/mandat/mandat-diffusion.form';
import { GeneralInformationsForm } from '@src/shared/forms/mandat/mandat-general-informations.form';
import { MandatPredefinedForm } from '@src/shared/forms/mandat/mandat-predefined.form';
import { FinancialForm } from '@src/shared/forms/sale-point/financial.form';
import { PriceForm } from '@src/shared/forms/sale-point/price.form';
import { SalePointForm } from '@src/shared/forms/sale-point/sale-point.form';
import { PappersCompaniesInterface } from '@src/shared/interaces/comapnies/entities/pappers-companies.interface';
import { CreateSalePointDto } from '@src/shared/interaces/salePoints/sale-point.interface';
import { LegalFormEnum } from '@src/shared/legal-form/interfaces/legal-form.interface';
import { Observable } from 'rxjs';

type Dto = CreateMandatDto | CreateSalePointDto;

@UntilDestroy()
@Component({
  selector: 'app-mandat-create-container',
  templateUrl: './mandat-create-container.component.html',
  styleUrl: './mandat-create-container.component.scss',
})
export class MandatCreateContainerComponent implements OnInit {
  @Select(AuthenticationSelectors.userCabinetId)
  cabinetId$!: Observable<string>;
  cabinetId!: string;
  annonceFormBuilder = new MandatAnnonceForm();
  commentaireFormBuilder = new MandatCommentaireForm();
  diffusionFormBuilder = new MandatDiffusionForm();
  generalInformationsFormBuilder = new GeneralInformationsForm(false);
  mandatPredefinedFormBuilder = new MandatPredefinedForm(false, this.cabinetId);
  companyFormBuilder = new CompanieForm();
  contactFormBuilder = new ContactForm(this.cabinetId);
  bailleurContactFormBuilder = new ContactForm(this.cabinetId, true);
  salesPointFormBuilder = new SalePointForm(false);
  priceFormBuilder = new PriceForm(false);
  financialFormBuilder = new FinancialForm(false);
  annonceForm!: FormGroup;
  commentaireForm!: FormGroup;
  diffusionForm!: FormGroup;
  generalInformationsForm!: FormGroup;
  mandatPredefinedForm!: FormGroup;
  mandantCompanyForm!: FormGroup;
  bailleurCompanyForm!: FormGroup;
  mandantContactForm!: FormGroup;
  bailleurContactForm!: FormGroup;
  salesPointForm!: FormGroup;
  priceForm!: FormGroup;
  financialForm!: FormGroup;
  isProspection = false;
  mandatValidators!: MandatValidators;
  salePointValidators!: SalePointValidators;

  loading = false;
  bailleurOrLocataire = 'bailleur';
  steps: string[] = [
    'Informations générales',
    'Mandant',
    this.bailleurOrLocataire,
    'Point de vente',
    'Financiers',
    'Prix',
    'Annonce',
  ];
  icons: string[] = [
    'description',
    'people_alt',
    'people_alt',
    'store_mall_directory',
    'euro',
    'euro',
    'campaign',
  ];
  stepsProspection: string[] = [
    'Informations générales',
    'Mandant',
    'Bailleur',
    'Point de vente',
    'Financiers',
    'Prix',
    'Commentaire',
  ];
  protected readonly MandatWallEnum = MandatWallEnum;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {
    this.cabinetId$.pipe(untilDestroyed(this)).subscribe(cabinetId => {
      this.cabinetId = cabinetId;
    });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.isProspection = params['prospection'] === 'true';
    });

    this.buildForms();
  }

  ngOnInit(): void {
    this.generalInformationsForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateBailleurOrLocataire();
      });
  }

  create() {
    const mandat: CreateMandatDto | null = this.getMandatDto();
    const salePoint: CreateSalePointDto | null = this.getSalePointDto();
    if (!mandat || !salePoint) return;

    const { morale_or_physique_mandant, morale_or_physique_bailleur } = mandat;

    const bailleurContact: ContactInterface | null =
      this.getContactIfValueExists(this.bailleurContactForm);
    const bailleurCompanies: PappersCompaniesInterface | null =
      this.getCompaniesIfMorale(morale_or_physique_bailleur);
    const mandantCompanies: PappersCompaniesInterface | null =
      this.getCompaniesIfMorale(morale_or_physique_mandant);
    const mandantContact = this.mandantContactForm.value;

    const mandatToCreate: CreateGlobalMandatDto = {
      mandat,
      salePoint,
      bailleurCompanies: bailleurCompanies ?? null,
      mandantContact: mandantContact,
      mandantCompanies: mandantCompanies ?? null,
      bailleurContact: bailleurContact ?? null,
    };

    this.store.dispatch(new CreateMandat(mandatToCreate)).subscribe(() => {
      console.log('Mandat created');
    });
  }

  private getMandatDto(): CreateMandatDto | null {
    const mandat: CreateMandatDto = this.mandatValidators.checkMandatValue();
    return this.validateDto(mandat, 'mandatValidators.checkMandatValue')
      ? mandat
      : null;
  }

  private getSalePointDto(): CreateSalePointDto | null {
    const salePoint: CreateSalePointDto =
      this.salePointValidators.checkSalePointValue();
    return this.validateDto(
      salePoint,
      'salePointValidators.checkSalePointValue'
    )
      ? salePoint
      : null;
  }

  private validateDto(dto: Dto | null, validatorName: string): boolean {
    if (dto == null) {
      console.error(`${validatorName} returned an invalid value`);
      return false;
    }

    return true;
  }

  private getCompaniesIfMorale(morale_or_physique: MoraleOrPhysiqueEnum) {
    if (morale_or_physique === MoraleOrPhysiqueEnum.morale) {
      const companies = this.mandantCompanyForm.value;
      console.log('companies', companies);
      return companies;
    }

    return null;
  }

  private getContactIfValueExists(contactForm: FormGroup) {
    if (contactForm.value) {
      const contact = contactForm.value;
      console.log('bailleurContactForm', contact);
      return contact;
    }

    return null;
  }

  private updateBailleurOrLocataire() {
    this.steps = [
      'Informations générales',
      'Mandant',
      'Bailleur',
      'Point de vente',
      'Financiers',
      'Prix',
      'Annonce',
    ];
    this.icons = [
      'description',
      'people_alt',
      'people_alt',
      'store_mall_directory',
      'euro',
      'euro',
      'campaign',
    ];
    if (
      this.generalInformationsForm.get('id_forme_juridique')?.value ===
        LegalFormEnum.bienImmobilier &&
      this.generalInformationsForm.get('etat_murs')?.value !==
        MandatWallEnum.free
    ) {
      this.bailleurOrLocataire = 'Locataire';
    } else {
      this.bailleurOrLocataire = 'Bailleur';
    }
    this.steps[2] = this.bailleurOrLocataire;
    if (
      this.generalInformationsForm.get('id_forme_juridique')?.value ===
        LegalFormEnum.rechercheDeLocataire ||
      (this.generalInformationsForm.get('id_forme_juridique')?.value ===
        LegalFormEnum.bienImmobilier &&
        this.generalInformationsForm.get('etat_murs')?.value ===
          MandatWallEnum.free)
    ) {
      this.steps.splice(2, 1);
      this.icons.splice(2, 1);
      this.bailleurCompanyForm.reset();
      this.bailleurContactForm.reset();
    }
  }

  private buildForms(): void {
    this.annonceForm = this.annonceFormBuilder.createFormGroup();
    this.commentaireForm = this.commentaireFormBuilder.createFormGroup();
    this.diffusionForm = this.diffusionFormBuilder.createFormGroup();
    this.generalInformationsForm =
      this.generalInformationsFormBuilder.createFormGroup();

    this.mandatPredefinedForm = new MandatPredefinedForm(
      false,
      this.cabinetId
    ).createFormGroup();

    this.mandantCompanyForm = this.companyFormBuilder.createFormGroup();
    this.bailleurCompanyForm = this.companyFormBuilder.createFormGroup();
    this.mandantContactForm = this.contactFormBuilder.createFormGroup();

    // Utilisation de this.cabinetId dans le FormBuilder
    this.bailleurContactForm = new ContactForm(
      this.cabinetId
    ).createFormGroup();

    this.salesPointForm = this.salesPointFormBuilder.createFormGroup();
    this.priceForm = this.priceFormBuilder.createFormGroup();
    this.financialForm = this.financialFormBuilder.createFormGroup();
    this.mandatValidators = new MandatValidators(
      this.annonceForm,
      this.commentaireForm,
      this.diffusionForm,
      this.generalInformationsForm,
      this.mandatPredefinedForm,
      this.isProspection,
      this.priceForm,
      this.financialForm
    );
    this.salePointValidators = new SalePointValidators(
      this.mandatPredefinedForm,
      this.financialForm,
      this.salesPointForm
    );
    this.cdr.markForCheck();
  }
}
