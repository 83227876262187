import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TvaExprimeEnum } from '@features/mandats/domain/entities/mandat.interface';
import { SelectItem } from '@libs/select/select-options';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrl: './price.component.scss',
})
export class PriceComponent implements OnInit {
  @Input({ required: true }) priceForm!: FormGroup;
  public tvaItems!: SelectItem[];

  ngOnInit() {
    this.tvaItems = [
      {
        id: TvaExprimeEnum.exprimes_ht,
        name: 'Honoraires HT',
      },
      {
        id: TvaExprimeEnum.exprimes_ttc,
        name: 'Honoraires TTC',
      },
    ];
  }
}
