import { Component, Input } from '@angular/core';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

@Component({
  selector: 'app-mandat-detail-diffusion',
  templateUrl: './mandat-detail-diffusion.component.html',
  styleUrl: './mandat-detail-diffusion.component.scss',
})
export class MandatDetailDiffusionComponent {
  @Input() mandat!: MandatDetailInterface;
}
