import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-annonce',
  templateUrl: './annonce.component.html',
  styleUrl: './annonce.component.scss',
})
export class AnnonceComponent {
  @Input({ required: true }) annonceForm!: FormGroup;
}
