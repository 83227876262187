<div
  *ngIf="value && value !== 0 && value !== '0' && value !== ''; else elseBlock"
  class="item">
  <p>{{ text }}</p>
  <h4>{{ value }}</h4>
</div>
<ng-template #elseBlock>
  <div class="item">
    <p>{{ text }}</p>
    <h4>-</h4>
  </div>
</ng-template>
