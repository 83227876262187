import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mandat-header',
  templateUrl: './mandat-header.component.html',
  styleUrl: './mandat-header.component.scss',
})
export class MandatHeaderComponent {
  @Input() isProspection = false;
  @Input() filteredMandatsLength = 0;
  @Input() mandatsLength = 0;
  @Output() resetFilters = new EventEmitter<void>();

  handleClickReset() {
    this.resetFilters.emit();
  }
}
