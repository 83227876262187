import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appCotationColor]',
  standalone: true,
})
export class CotationColorDirective implements OnChanges {
  @Input() appCotationColor: string = 'A'; // defaults to 'A'

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges() {
    this.setColor();
  }

  private setColor() {
    let color: string;

    switch (this.appCotationColor) {
      case 'A+':
        color = 'green';
        break;
      case 'A':
        color = 'yellow';
        break;
      case 'B':
        color = 'orange';
        break;
      case 'C':
      case 'D':
        color = 'red';
        break;
      default:
        color = 'black';
        break;
    }

    this.renderer.setStyle(this.el.nativeElement, 'color', color);
  }
}
