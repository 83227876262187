<form [formGroup]="financialForm" class="create-form">
  <div class="create-form">
    <app-input-slide
      formControlName="total_produits_exploit"
      inputType="price"
      placeholder="Total des produits d'exploitation"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="ca"
      inputType="price"
      placeholder="Chiffre d'affaires"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="achats"
      inputType="price"
      placeholder="Achats de marchandises"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="variation_stock"
      inputType="price"
      placeholder="Variation de stock"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="impots"
      inputType="price"
      placeholder="Impôts et taxes"
      type="number"
      width="fit" />
  </div>
  <div class="separator"></div>
  <div class="create-form">
    <app-input-slide
      formControlName="charges_externes"
      inputType="price"
      placeholder="Charges externes"
      type="number"
      width="fit" />
    <app-input-slide
      [inputControl]="getControl('loyer_annuel')"
      [required]="true"
      formControlName="loyer_annuel"
      inputType="price"
      placeholder="Dont loyer Annuel HC"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="charges_locatives"
      inputType="price"
      placeholder="Dont charges locatives"
      type="number"
      width="fit" />
  </div>
  <div class="separator"></div>
  <div class="create-form">
    <app-input-slide
      formControlName="salaires_total"
      inputType="price"
      placeholder="Total des salaires"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="salaires_exploitant"
      inputType="price"
      placeholder="Dont rénumération exploitant(s)"
      type="number"
      width="fit" />
  </div>
  <div class="separator"></div>
  <div class="create-form">
    <app-input-slide
      formControlName="charges_sociales_total"
      inputType="price"
      placeholder="Total des charges sociales"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="charges_sociales_exploitant"
      inputType="price"
      placeholder="Dont sociales exploitant(s)"
      type="number"
      width="fit" />
  </div>
  <div class="separator"></div>
  <div class="create-form">
    <app-input-slide
      formControlName="amortissements"
      inputType="price"
      placeholder="Dotations aux amortissements"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="autres_charges"
      inputType="price"
      placeholder="Autres charges"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="montant_ebe"
      inputType="price"
      placeholder="EBE"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="montant_foncier"
      inputType="price"
      placeholder="Montant taxe foncière"
      type="number"
      width="fit" />
    <app-input-slide
      formControlName="depot_garantie"
      inputType="price"
      placeholder="Montant dépôt de garantie"
      type="number"
      width="fit" />
  </div>
  <div class="separator"></div>
  <div class="form-group">
    <label for="provision">Paiement loyer et provision :</label>
    <app-select
      [items]="provisionMensuelItems"
      formControlName="provision_mensuel"
      id="provision"
      size="fit" />
  </div>
  <div class="form-group">
    <label for="tva">TVA loyer et provision :</label>
    <app-select
      [items]="provisionTvaItems"
      formControlName="provision_tva"
      id="tva"
      size="fit" />
  </div>
  <div class="form-group">
    <label for="revision">Révision du loyer</label>
    <app-select
      [items]="revisionLoyerItems"
      formControlName="revision_annuelle"
      id="revision"
      size="fit" />
  </div>
  <div class="form-group">
    <label for="foncier">Foncier à charge du :</label>
    <app-select
      [items]="foncierAChargeItems"
      formControlName="foncier_a_charge"
      id="foncier"
      size="fit" />
  </div>
</form>
