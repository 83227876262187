<div
  [ngClass]="{
    'input-focused': checkIfValueExist() || isInputFocused,
    desktop: forDesktop
  }"
  class="input_slide-container">
  <input
    (blur)="onFocus(false)"
    (change)="executeSearch()"
    (focus)="onFocus(true)"
    (input)="updateValue($event)"
    (keyup)="executeSearch()"
    [(ngModel)]="searchTerm"
    [id]="inputId"
    [matAutocomplete]="auto"
    type="text"
    width="fit" />
  <label [for]="inputId" class="placeholder">{{ placeholder }}</label>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let buyer of matchingBuyers" [value]="buyer">
      <div class="option-item">
        <p>{{ buyer.nom }} {{ buyer.prenom }}</p>
        <p class="email">{{ buyer.email }}</p>
        <p class="email">{{ buyer.mobile }}</p>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
