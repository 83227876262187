<div class="visite">
  <img
    alt="piéton"
    height="30"
    ngSrc="assets/mandat-detail/visite.png"
    width="30" />
  <p
    *ngIf="visites?.length === 0 || visites === null; else visiteBlock"
    class="no-visit">
    Aucune visite
  </p>
  <ng-template #visiteBlock>
    <div>
      <p>
        Il y a eu
        <span
          (click)="showVisites()"
          (keyup.enter)="showVisites()"
          class="link"
          tabindex="0"
          >{{ visites?.length }}</span
        >
        visite(s) sur ce bien
      </p>
      <div
        *ngFor="let visite of visites"
        [ngClass]="{ hidden: !visitesIsShowed }"
        class="visite-item">
        <div class="avatar">
          <span>- </span>
          <app-consultant-avatar [consultantId]="10000160" />
          <p class="date">Le {{ visite.created_at.date | date: 'd MMMM y' }}</p>
        </div>
        <div class="content">
          <div class="acquereurs">
            <p>Acquéreur(s):</p>
            <div class="list">
              <p>NOM ET Prénom ici</p>
              <p>NOM ET Prénom ici</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
