<form [formGroup]="mandatPredefinedForm" class="create-form">
  <div class="form-group-checkbox">
    <label for="mandant">Personne physique uniquement :</label>
    <mat-checkbox
      [formControl]="moralOrPhysicalControl"
      class="form-control"
      id="mandant"></mat-checkbox>
  </div>
</form>

<form
  *ngIf="shouldDisplaySearchCompaniesBar"
  [formGroup]="companiesMandantForm"
  class="create-form">
  <div class="form-group">
    <app-search-pappers
      (optionSelectedEvent)="getPappersResult($event)"
      id="company"></app-search-pappers>
  </div>
</form>

<div class="create-form">
  <app-mandat-detail-card-morale
    *ngIf="companiesMandantForm.get('siren')?.value"
    [companie]="companiesMandantForm.value"></app-mandat-detail-card-morale>
</div>
<div class="separator"></div>

<!-- Bouton pour rendre le formulaire accessible -->
<div *ngIf="!shouldDisplaySearchCompaniesBar" class="btn-container-companies">
  <app-btn
    (click)="enableForm()"
    color="primary"
    text="Modifier l'entreprise"></app-btn>
</div>
