import { Injectable } from '@angular/core';
import { VisiteInterface } from '@features/visites/domain/models/visite-interface';
import { VisitesApiPorts } from '@features/visites/domain/ports/api/visites-api.ports';
import { LoadSilenceVisites } from '@features/visites/domain/redux/actions/visites.actions';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

export class VisitesStateModel {
  visites: VisiteInterface[] | undefined;
}

export const defaultVisistesState: VisitesStateModel = {
  visites: [],
};

@State<VisitesStateModel>({
  name: 'visites',
  defaults: defaultVisistesState,
})
@Injectable()
export class VisitesState implements NgxsOnInit {
  constructor(private visitesService: VisitesApiPorts) {}

  ngxsOnInit(ctx: StateContext<VisitesStateModel>) {
    ctx.dispatch(new LoadSilenceVisites());
  }

  @Action(LoadSilenceVisites)
  async loadSilenceVisites(
    ctx: StateContext<VisitesStateModel>
  ): Promise<void> {
    try {
      const visites = await lastValueFrom(this.visitesService.getVisites());
      ctx.patchState({
        visites: visites,
      });
    } catch (error) {
      console.error('Erreur lors du chargement des visites', error);
    }
  }
}
