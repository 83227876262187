import { FormSectionBase } from '@src/shared/forms/form.class';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export class MandatDiffusionForm extends FormSectionBase {
  createFormGroup(): FormGroup {
    return new FormGroup({
      vilcespme: new FormControl('', Validators.required),
      CPdiffusion: new FormControl('', Validators.required),
      departement_diffusion: new FormControl('', Validators.required),
    });
  }
}
