import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  RendererFactory2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';
import { MandatStateEnum } from '@features/mandats/domain/entities/mandat.interface';
import {
  SetSearchAddress,
  SetSearchCity,
} from '@features/mandats/domain/redux/actions/mandats.actions';
import {
  AddMandatToVisitForm,
  RemoveMandatFromVisitForm,
} from '@features/visit-form/domain/redux/actions/visit-form.actions';
import { VisitFormSelectors } from '@features/visit-form/domain/redux/selectors/visit-form.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';

@UntilDestroy()
@Component({
  selector: 'app-mandat-active-table',
  templateUrl: './mandat-active-table.component.html',
  styleUrls: ['./mandat-active-table.component.scss'],
  animations: [
    trigger('swipeRight', [
      state(
        'default',
        style({
          transform: 'translateX(0)',
        })
      ),
      state(
        'swipedRight',
        style({
          transform: 'translateX()',
        })
      ),
      transition('default => swipedRight', [animate('1s')]),
      transition('swipedRight => default', [animate('1s')]),
    ]),
  ],
})
export class MandatActiveTableComponent
  implements OnChanges, AfterViewInit, OnInit
{
  @Input() mandats!: MandatDetailInterface[];
  @Input() mandatsToDisplay!: MandatDetailInterface[];
  @Input() mandatShouldBeDisplayed!: boolean;
  @Input() isProspection: boolean = false;
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  scrollableDivChanged = false;
  startX!: number | null;
  startY!: number | null;
  currentTdIndex!: number;
  swipedRightIndexes: { [key: number]: boolean } = {};
  limite = 20;
  renderer: Renderer2;
  mandatIds: number[] = [];
  windowWidth: number = window.innerWidth;
  protected readonly MandatStateEnum = MandatStateEnum;
  protected readonly VisitFormSelectors = VisitFormSelectors;
  protected readonly window = window;

  constructor(
    private rendererFactory: RendererFactory2,
    protected store: Store
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    this.store
      .select(VisitFormSelectors.mandatsIds)
      .pipe(untilDestroyed(this))
      .subscribe(ids => {
        this.mandatIds = ids;
      });
  }

  resetAllSwipes() {
    Object.keys(this.swipedRightIndexes).forEach(key => {
      this.swipedRightIndexes[parseInt(key)] = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mandatsToDisplay']) {
      this.limite = 20;
      this.scrollToTop(this.scrollableDiv);
    }
  }

  ngAfterViewInit() {
    if (this.scrollableDivChanged) {
      this.scrollableDiv.nativeElement.scrollTop = 0;
      this.scrollableDivChanged = false;
    }
  }

  onTouchStart(event: TouchEvent, index: number) {
    console.log('onTouchStart triggered', { event, index });
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
    this.currentTdIndex = index;
  }

  onTouchMove(event: TouchEvent, index: number) {
    if (!this.startX || !this.startY) {
      return;
    }

    const moveX = event.changedTouches[0].clientX;
    const moveY = event.changedTouches[0].clientY;

    const diffX = Math.abs(moveX - this.startX);
    const diffY = Math.abs(moveY - this.startY);

    if (diffX > 10 && diffX > diffY) {
      this.resetAllSwipes();
      if (event.cancelable) {
        event.preventDefault();
      }

      if (this.currentTdIndex !== index) {
        this.resetSwipe();
      }

      const swipeDirection = moveX > this.startX ? 'right' : 'left';
      this.swipedRightIndexes[index] = swipeDirection === 'left';
      this.currentTdIndex = index;
    }
  }

  resetSwipe() {
    this.swipedRightIndexes[this.currentTdIndex] = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableScroll(e: any) {
    const isBottom =
      e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight * 0.8;

    if (isBottom) {
      this.limite += 20; // load 20 more items
    }
  }

  scrollToTop(el?: ElementRef) {
    this.renderer.setProperty(el?.nativeElement, 'scrollTop', 0);
  }

  addMandatToListVisitForm(mandat: MandatDetailInterface) {
    if (!this.mandatIds.includes(mandat.mandat.id)) {
      this.store.dispatch(new AddMandatToVisitForm(mandat));
    } else {
      this.store.dispatch(new RemoveMandatFromVisitForm(mandat.mandat.id));
    }
  }

  addAddresToSearchTab(route: string) {
    this.store.dispatch(new SetSearchAddress(route));
  }

  addCityToSearchTab(city: string) {
    this.store.dispatch(new SetSearchCity(city));
  }

  shouldHidePrice(index: number): boolean {
    return this.swipedRightIndexes[index] && this.windowWidth < 400;
  }

  shouldHideRent(index: number): boolean {
    return (
      this.swipedRightIndexes[index] &&
      this.windowWidth >= 400 &&
      this.windowWidth < 850
    );
  }

  shouldHideCity(index: number): boolean {
    return (
      this.swipedRightIndexes[index] &&
      this.windowWidth >= 850 &&
      this.windowWidth < 1560
    );
  }

  shouldHideMandants(index: number): boolean {
    return this.swipedRightIndexes[index] && this.windowWidth >= 1560;
  }
}
