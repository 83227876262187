<div class="global-container">
  <aside class="aside">
    <div class="text-container">
      <p class="text-line">{{ mandat | mandatStateDisplayer }}</p>
    </div>
    <div class="btn-container">
      <app-btn [text]="'Enregistrer'" size="medium"></app-btn>
      <app-btn [text]="'Annuler'" color="ternary" size="medium"></app-btn>
    </div>
  </aside>

  <div class="edit-container">
    <h2>Informations générales</h2>
    <app-general-information
      [generalInformationForm]="
        this.generalInformationsForm
      "></app-general-information>

    <h2>Mandant</h2>
    <app-mandant-companies
      [companiesMandantForm]="this.mandantCompanyForm"
      [isEditMode]="true"
      [mandatPredefinedForm]="
        this.mandatPredefinedForm
      "></app-mandant-companies>

    <app-mandant-contact
      [contactMandantForm]="this.mandantContactForm"></app-mandant-contact>
    <h2>Bailleur</h2>
    <app-bailleur-companies
      [companiesBailleurForm]="this.bailleurCompanyForm"
      [isEditMode]="true"
      [mandatPredefinedForm]="
        this.mandatPredefinedForm
      "></app-bailleur-companies>

    <app-bailleur-contact
      [bailleurForm]="this.bailleurContactForm"></app-bailleur-contact>

    <h2>Point de vente</h2>
    <app-sale-point [salePointForm]="this.salesPointForm"></app-sale-point>
    <h2>Financiers</h2>
    <app-financial [financialForm]="this.financialForm"></app-financial>
    <h2>Prix</h2>
    <app-price [priceForm]="this.priceForm"></app-price>
    <h2>Annonce</h2>
    <app-annonce [annonceForm]="this.annonceForm"></app-annonce>

    <h2>Diffusion</h2>
    <app-diffusion
      [commentaireForm]="commentaireForm"
      [diffusionForm]="this.diffusionForm"></app-diffusion>
  </div>
</div>
