import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { ActivatedRoute } from '@angular/router';
import { Directionality } from '@angular/cdk/bidi';

@Component({
  selector: 'app-mandat-create-stepper',
  templateUrl: './mandat-create-stepper.component.html',
  styleUrl: './mandat-create-stepper.component.scss',
  providers: [
    { provide: CdkStepper, useExisting: MandatCreateStepperComponent },
  ],
})
export class MandatCreateStepperComponent extends CdkStepper implements OnInit {
  @Input() stepNames!: string[];
  @Input() icons!: string[];
  @Input() isProspection = false;
  @Output() create: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor(
    private route: ActivatedRoute,
    dir: Directionality,
    cdr: ChangeDetectorRef,
    ref: ElementRef
  ) {
    super(dir, cdr, ref);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.isProspection = params['prospection'] === 'true';
    });
  }

  onClick(index: number): void {
    this.selectedIndex = index;
  }

  onSubmit() {
    this.create.emit();
  }
}
