import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CitySearchInterface } from '@libs/city-search/city-search.interface';

@Component({
  selector: 'app-diffusion',
  templateUrl: './diffusion.component.html',
  styleUrl: './diffusion.component.scss',
})
export class DiffusionComponent {
  @Input({ required: true }) diffusionForm!: FormGroup;
  @Input({ required: true }) commentaireForm!: FormGroup;

  handleCity(event: CitySearchInterface) {
    this.diffusionForm.patchValue({
      vilcespme: event.ville,
      CPdiffusion: event.postal_code,
      departement_diffusion: +event.numero_departement,
    });
  }
}
