<form [formGroup]="generalInformationForm" class="create-form">
  <div class="form-group">
    <label class="required" for="consultant">Consultant </label>
    <app-select
      [items]="consultants"
      formControlName="id_consultant"
      id="consultant"
      size="fit" />
  </div>
  <div
    *ngIf="generalInformationForm.get('id_consultant')?.value"
    class="form-group">
    <label class="required" for="legalForm">Forme juridique </label>
    <app-select
      [items]="legalFormObject"
      formControlName="id_forme_juridique"
      id="legalForm"
      size="fit" />
  </div>
  <div
    *ngIf="generalInformationForm.get('id_forme_juridique')?.value"
    class="form-group">
    <label class="required" for="activité">Activité </label>
    <app-select
      [items]="activitiesObject"
      formControlName="id_activite"
      id="activité"
      size="fit" />
  </div>
  <div
    *ngIf="generalInformationForm.get('id_activite')?.value"
    class="form-group">
    <label class="required" for="Rubrique">Rubriques </label>
    <app-select
      [items]="rubriquesItems"
      formControlName="id_rubrique_cession_pme"
      id="Rubrique"
      size="fit" />
  </div>
  <div
    *ngIf="generalInformationForm.get('id_rubrique_cession_pme')?.value"
    class="form-group-checkbox">
    <label for="exclusif">Mandat exclusif : </label>
    <mat-checkbox [formControl]="exclusifControl" id="exclusif" />
  </div>
  <div
    *ngIf="
      generalInformationForm.get('id_rubrique_cession_pme')?.value &&
      generalInformationForm.get('id_forme_juridique')?.value === 4
    "
    class="form-group">
    <label for="murs">Murs : </label>
    <app-select
      [items]="wallStateItem"
      [required]="true"
      formControlName="etat_murs"
      id="murs"
      size="fit" />
  </div>
</form>
