<form [formGroup]="diffusionForm" class="create-form">
  <div class="form-group form-group-textarea">
    <app-city-search
      (cityChange)="handleCity($event)"
      [displayedValue]="
        (diffusionForm.get('vilcespme')?.value
          ? diffusionForm.get('vilcespme')?.value
          : '') +
        (diffusionForm.get('vilcespme')?.value &&
        diffusionForm.get('CPdiffusion')?.value
          ? ' - '
          : '') +
        (diffusionForm.get('CPdiffusion')?.value
          ? diffusionForm.get('CPdiffusion')?.value
          : '')
      "
      [isCreation]="true"
      [required]="true"
      id="citysearch">
    </app-city-search>
  </div>
</form>
<form [formGroup]="commentaireForm" class="create-form">
  <div class="form-group form-group-textarea">
    <label for="annonce-comment">Commentaire :</label>
    <textarea formControlName="commentaire" id="annonce-comment"></textarea>
  </div>
</form>
