import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  SetActualUrl,
  SetPreviousUrl,
} from '@features/nav/domain/redux/actions/nav.actions';

export class NavStateModel {
  actualUrl!: string;
  previousUrl!: string;
}

export const defaultNavState: NavStateModel = {
  actualUrl: '',
  previousUrl: '',
};

@State<NavStateModel>({
  name: 'nav',
  defaults: defaultNavState,
})
@Injectable()
export class NavState {
  // set actual url
  @Action(SetActualUrl)
  setActualUrl(ctx: StateContext<NavStateModel>, action: SetActualUrl): void {
    const state = ctx.getState();
    ctx.setState({ ...state, actualUrl: action.url });
  }

  @Action(SetPreviousUrl)
  setPreviousUrl(
    ctx: StateContext<NavStateModel>,
    action: SetPreviousUrl
  ): void {
    const state = ctx.getState();
    ctx.setState({ ...state, previousUrl: action.url });
  }
}
