import { NgModule } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { SelectContactComponent } from './select-contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption,
} from '@angular/material/autocomplete';

@NgModule({
  declarations: [SelectContactComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatOption,
  ],
  exports: [SelectContactComponent, NgClass],
})
export class AppSelectContactModule {}
