import { Component, Input } from '@angular/core';
import { MandatDetailInterface } from '@features/mandats/domain/entities/mandat-detail.interface';

@Component({
  selector: 'app-mandat-detail-sub-header',
  templateUrl: './mandat-detail-sub-header.component.html',
  styleUrl: './mandat-detail-sub-header.component.scss',
})
export class MandatDetailSubHeaderComponent {
  @Input() mandat!: MandatDetailInterface;
}
