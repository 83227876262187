import { FormGroup } from '@angular/forms';
import { CreateSalePointDto } from '@src/shared/interaces/salePoints/sale-point.interface';

export class SalePointValidators {
  salePointValue?: CreateSalePointDto;

  constructor(
    private mandatPredefinedForm: FormGroup,
    private financialForm: FormGroup,
    private salePointForm: FormGroup
  ) {}

  checkSalePointValue() {
    this.salePointValue = {
      id_cabinet: this.mandatPredefinedForm.value.id_cabinet,
      enseigne: this.salePointForm.value.enseigne.trim(),
      url: this.salePointForm.value.url?.trim(),
      place_id: this.salePointForm.value.place_id.trim(),
      street_number: this.salePointForm.value.street_number,
      formatted_address: this.salePointForm.value.formatted_address.trim(),
      type_voie: this.salePointForm.value.type_voie?.trim(),
      route: this.salePointForm.value.route.trim(),
      comp_adresse: this.salePointForm.value.comp_adresse?.trim(),
      administrative_area_level_1:
        this.salePointForm.value.administrative_area_level_1?.trim(),
      administrative_area_level_2:
        this.salePointForm.value.administrative_area_level_2?.trim(),
      postal_code: this.salePointForm.value.postal_code,
      locality: this.salePointForm.value.locality.trim(),
      latitude: this.salePointForm.value.latitude,
      longitude: this.salePointForm.value.longitude,
      surface_totale: this.salePointForm.value.surface_totale,
      surface_commerciale: this.salePointForm.value.surface_commerciale,
      surface_annexe: this.salePointForm.value.surface_annexe,
      surface_bureaux: this.salePointForm.value.surface_bureaux,
      surface_terrain: this.salePointForm.value.surface_terrain,
      surface_terrasse: this.salePointForm.value.surface_terrasse,
      lineaire: this.salePointForm.value.lineaire,
      echeance_bail: this.salePointForm.value.echeance_bail,
      acces_pl: this.salePointForm.value.acces_pl,
      accessibilite_pmr: this.salePointForm.value.accessibilite_pmr,
      presence_extraction: this.salePointForm.value.presence_extraction,
      quai_dechargement: this.salePointForm.value.quai_dechargement,
      copropriete: this.salePointForm.value.copropriete,
      total_produits_exploit: this.financialForm.value.total_produits_exploit,
      ca: this.financialForm.value.ca,
      achats: this.financialForm.value.achats,
      variation_stock: this.financialForm.value.variation_stock,
      impots: this.financialForm.value.impots,
      charges_externes: this.financialForm.value.charges_externes,
      charges_locatives: this.financialForm.value.charges_locatives,
      salaires_total: this.financialForm.value.salaires_total,
      salaires_exploitant: this.financialForm.value.salaires_exploitant,
      charges_sociales_total: this.financialForm.value.charges_sociales_total,
      charges_sociales_exploitant:
        this.financialForm.value.charges_sociales_exploitant,
      amortissements: this.financialForm.value.amortissements,
      autres_charges: this.financialForm.value.autres_charges,
      montant_ebe: this.financialForm.value.montant_ebe,
      provision_mensuel: this.financialForm.value.provision_mensuel,
      provision_tva: this.financialForm.value.provision_tva,
      revision_annuelle: this.financialForm.value.revision_annuelle,
      foncier_a_charge: this.financialForm.value.foncier_a_charge,
      montant_foncier: this.financialForm.value.montant_foncier,
      depot_garantie: this.financialForm.value.depot_garantie,
      loyer_annuel: this.financialForm.value.loyer_annuel,
    };
    return this.salePointValue;
  }
}
