<div class="container">
  <div class="list">
    <app-mandat-detail-list-item
      [text]="'Chiffre d\'affaires '"
      [value]="
        mandat.salePoints.ca | formatEuroWithNull
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Loyer annuel'"
      [value]="
        mandat.salePoints.loyer_annuel | formatEuroWithNull
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Taxe Fonciere'"
      [value]="
        mandat.salePoints.montant_foncier | formatEuroWithNull
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Montant EBE'"
      [value]="
        mandat.salePoints.montant_ebe | formatEuroWithNull
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      *ngIf="+mandat.mandat.prix_de_presentation !== 0"
      [text]="'Prix net vendeur'"
      [value]="
        calculatedNetVendeur(mandat) | formatEuroWithNull
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Charges locative'"
      [value]="
        mandat.salePoints.charges_locatives | formatEuroWithNull
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Dépot de garantie'"
      [value]="
        mandat.salePoints.depot_garantie | formatEuroWithNull
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Surface commercial'"
      [value]="
        mandat.salePoints.surface_commerciale | formatArea
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Surface annexe'"
      [value]="
        mandat.salePoints.surface_annexe | formatArea
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Surface terrasse'"
      [value]="
        mandat.salePoints.surface_terrasse | formatArea
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Surface bureaux'"
      [value]="
        mandat.salePoints.surface_bureaux | formatArea
      "></app-mandat-detail-list-item>

    <app-mandat-detail-list-item
      [text]="'Surface terrain'"
      [value]="
        mandat.salePoints.surface_terrain | formatArea
      "></app-mandat-detail-list-item>

    <app-mandat-detail-list-item
      [text]="'Lineaire vitrine'"
      [value]="
        mandat.salePoints.lineaire | formatArea
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Surface total'"
      [value]="
        mandat.salePoints.surface_totale | formatArea
      "></app-mandat-detail-list-item>

    <app-mandat-detail-list-item
      [text]="'Honoraires vendeur'"
      [value]="
        mandat.mandat.honoraires_vendeur | formatEuroWithNull
      "></app-mandat-detail-list-item>
    <app-mandat-detail-list-item
      [text]="'Honoraires acquéreur'"
      [value]="
        mandat.mandat.honoraires_acquereur | formatEuroWithNull
      "></app-mandat-detail-list-item>
  </div>
</div>

<div class="separator"></div>

<div class="sale-points-attribute-container">
  <p>
    Extraction :
    {{ mandat.salePoints.presence_extraction | booleanToYesOrNo }}
  </p>
  <p>Copropriété : {{ mandat.salePoints.copropriete | booleanToYesOrNo }}</p>
  <div
    *ngIf="
      !!+mandat.salePoints.acces_pl ||
      !!+mandat.salePoints.quai_dechargement ||
      !!+mandat.salePoints.accessibilite_pmr
    "
    class="access">
    <p>Accesssibilité :</p>
    <div>
      <img
        *ngIf="!!+mandat.salePoints.acces_pl"
        alt="disabled"
        class="sale-points-attribute"
        height="20"
        ngSrc="../../../../../../../../assets/mandat-detail/camion.png"
        width="20" />
      <img
        *ngIf="!!+mandat.salePoints.quai_dechargement"
        alt="disabled"
        class="sale-points-attribute"
        height="20"
        ngSrc="../../../../../../../../assets/icons/chariot.png"
        width="20" />
      <img
        *ngIf="!!+mandat.salePoints.accessibilite_pmr"
        alt="disabled"
        class="sale-points-attribute"
        height="20"
        ngSrc="../../../../../../../../assets/mandat-detail/pmr.png"
        width="20" />
    </div>
  </div>
</div>
