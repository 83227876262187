import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mandat-detail-list-item',
  templateUrl: './mandat-detail-list-item.component.html',
  styleUrl: './mandat-detail-list-item.component.scss',
})
export class MandatDetailListItemComponent {
  @Input() text!: string;
  @Input() value!: string | number;
}
