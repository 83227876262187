import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exclusive',
  standalone: true,
})
export class ExclusivePipe implements PipeTransform {
  transform(value: string): string {
    if (value.toUpperCase() === 'OUI') {
      return 'Exclusif';
    } else if (value.toUpperCase() === 'NON') {
      return 'Non Exclusif';
    }
    return value;
  }
}
