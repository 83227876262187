<section class="example-container" xmlns="http://www.w3.org/1999/html">
  <div class="example-step-navigation-bar">
    <div
      (click)="onClick(i)"
      (keyup.enter)="onClick(i)"
      *ngFor="let step of steps; let i = index; let isLast = last"
      class="nav-container"
      tabindex="0">
      <p
        [ngClass]="{
          'example-active': selectedIndex === i,
          'step-valid': step.completed
        }"
        class="example-step">
        <mat-icon inline="true">{{ icons[i] }}</mat-icon>
      </p>
      <span
        [ngClass]="{
          'example-active-divider': selectedIndex === i,
          'step-valid': step.completed,
          'last-divider': isLast
        }"
        class="divider"></span>
    </div>
  </div>

  <header>
    <h1 *ngIf="!isProspection">Création de mandat</h1>
    <h1 *ngIf="isProspection">Création de fiche de prospection</h1>
    <h2>
      {{ stepNames[selectedIndex] }}
      <mat-icon inline="true">{{ icons[selectedIndex] }}</mat-icon>
    </h2>
  </header>
  <div class="second-container">
    <div class="content-container">
      <div [style.display]="selected ? 'block' : 'none'">
        <ng-container
          [ngTemplateOutlet]="selected?.content || null"></ng-container>
      </div>
    </div>
    <footer>
      <div>
        <button
          [disabled]="selectedIndex === 0"
          cdkStepperPrevious
          class="btn previous">
          Précédent
        </button>
      </div>
      <div class="">
        <button
          *ngIf="selectedIndex !== steps.length - 1"
          [disabled]="selected?.stepControl && !selected?.stepControl?.valid"
          cdkStepperNext
          class="btn"
          type="button">
          Suivant
        </button>

        <button
          (click)="onSubmit()"
          *ngIf="selectedIndex === steps.length - 1 && !this.isProspection"
          [disabled]="selected?.stepControl && !selected?.stepControl?.valid"
          class="btn">
          Créer le PM
        </button>

        <button
          (click)="onSubmit()"
          *ngIf="selectedIndex === steps.length - 1 && this.isProspection"
          [disabled]="selected?.stepControl && !selected?.stepControl?.valid"
          class="btn">
          Créer la FP
        </button>
      </div>
    </footer>
  </div>
</section>
