import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TvaAssujetiEnum } from '@features/mandats/domain/entities/mandat.interface';
import { SelectItem } from '@libs/select/select-options';

import {
  FoncierAChargeEnum,
  ProvisionMensuelEnum,
  RevisionLoyerEnum,
} from '@src/shared/interaces/salePoints/sale-point.interface';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrl: './financial.component.scss',
})
export class FinancialComponent implements OnInit {
  @Input({ required: true }) financialForm!: FormGroup;
  public provisionMensuelItems!: SelectItem[];
  public provisionTvaItems!: SelectItem[];
  public revisionLoyerItems!: SelectItem[];
  public foncierAChargeItems!: SelectItem[];

  ngOnInit() {
    this.provisionMensuelItems = [
      {
        id: ProvisionMensuelEnum.mensuel,
        name: 'Mensuel',
      },
      {
        id: ProvisionMensuelEnum.trimestriel,
        name: 'Trimestriel',
      },
    ];
    this.provisionTvaItems = [
      {
        id: TvaAssujetiEnum.assujetti,
        name: 'Assujetti',
      },
      {
        id: TvaAssujetiEnum.non_assujetti,
        name: 'Non assujetti',
      },
    ];
    this.revisionLoyerItems = [
      {
        id: RevisionLoyerEnum.annuelle,
        name: 'Annuelle',
      },
      {
        id: RevisionLoyerEnum.triennale,
        name: 'Triennale',
      },
    ];
    this.foncierAChargeItems = [
      {
        id: FoncierAChargeEnum.bailleur,
        name: 'Bailleur',
      },
      {
        id: FoncierAChargeEnum.preneur,
        name: 'Preneur',
      },
    ];
  }

  getControl(name: string): FormControl {
    return this.financialForm.get(name) as FormControl;
  }
}
