<div class="card">
  <div class="mandant-item">
    <div class="mandant-item">
      <img
        alt="entreprise"
        height="20"
        ngSrc="../../../../../../../../assets/mandat-detail/business.png"
        width="20" />

      <p>{{ checkData(companie.denomination) }}</p>
    </div>
  </div>
  <div class="mandant-item">
    <img
      alt="téléphone"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/siren.png"
      width="20" />
    <a
      href="https://www.pappers.fr/entreprise/{{ companie.siren }}"
      target="_blank"
      >{{ checkData(companie.siren) }}</a
    >
  </div>
  <div class="mandant-item">
    <img
      alt="emplacement"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/place.png"
      width="20" />
    <p *ngIf="companie.ville; else villeBlock">
      {{ companie.adresse_ligne_1 }} {{ companie.complement_adresse }}
      {{ companie.code_postal }} {{ companie.ville }}

      <img
        alt="geolocalisation"
        height="12"
        ngSrc="../../../../../../../../assets/icons/geo-green.png"
        width="12" />
    </p>
    <ng-template #villeBlock><p>Aucune donnée disponible</p></ng-template>
  </div>
  <div class="mandant-item">
    <img
      alt="activité"
      height="20"
      ngSrc="../../../../../../../../assets/mandat-detail/bag.png"
      width="20" />
    <p>{{ checkData(companie.libelle_code_naf) }}</p>
  </div>
</div>
