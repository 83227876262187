import { Pipe, PipeTransform } from '@angular/core';
import { activitesObject } from '@src/shared/activites/objects/activities.object';

@Pipe({
  name: 'activitiesName',
  standalone: true,
})
export class ActivitiesNamePipe implements PipeTransform {
  transform(id: number): string {
    const activity = activitesObject.find(act => act.id === id);
    return activity ? activity.name : '';
  }
}
